import { ProductPerformanceI } from "_interfaces/transaction.interfaces";
import ContentContainer from "components/container";
import { Columns, Table } from "components/table/table";
import { useGetAffiliateProductPerformanceQuery } from "services/modules/transaction";

const ProductPerformanceSection = ({ id }: { id: string }) => {
  const { data, isFetching, isLoading } = useGetAffiliateProductPerformanceQuery(id!, { skip: !id });

  const productHeader: Columns<ProductPerformanceI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Category",
    },
    {
      fieldId: "count",
      label: "Order",
    },
    {
      fieldId: "price",
      label: "Net Sales",
      render: (item) => `$${(item.price / 100).toLocaleString('en-US', { currency: "USD" })}`
    },
  ]

  return (
    <ContentContainer>
      <h3 className="text-xl text-[#262626] font-semibold mb-4">
        Product Performance
      </h3>
      <div className="w-full overflow-auto">
        <Table<ProductPerformanceI>
          data={data?.products}
          loading={isLoading || isFetching}
          columns={productHeader}
        />
      </div>
    </ContentContainer>
  );
};

export default ProductPerformanceSection;
