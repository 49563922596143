import {
  GetProductQuery,
  PostType,
  ProductI,
  SortType,
} from "_interfaces/post.interface";
import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import { Button } from "react-daisyui";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDeleteProductMutation, useGetPinnedProductQuery, useGetProductQuery } from "services/modules/crafter";
import PinnedProductSection from "./sections/pinned-product";
import { errorHandler } from "services/errorHandler";

export const productManagementRouteName = "";
const ProductPage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<GetProductQuery>({
    page: 1,
    limit: 10,
    sortType: SortType.Latest,
  });
  const { data, isLoading, refetch } = useGetProductQuery(params);
  const [deleteProduct] = useDeleteProductMutation();

  const handleDelete = async (id: string) => {
    try {
      await deleteProduct(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  }

  const header: Columns<ProductI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Title",
    },
    {
      fieldId: "price",
      label: "Price",
      render: (data) =>
        data.enableDiscount &&
          moment(data.discountPeriod).isAfter(new Date()) ? (
          <div className="flex flex-row justify-center items-center gap-0.5">
            <div className="font-semibold text-lg">${data.discount[0]}</div>
            <div className="line-through text-xs text-gray-600">
              (${data.price[0]})
            </div>
          </div>
        ) : (
          <div className="font-semibold text-lg">${data.price[0]}</div>
        ),
    },
    {
      fieldId: "postType",
      label: "Status",
      render: (data) =>
        data.postType === PostType.Publish
          ? moment(new Date(data.publishAt!)).isSameOrBefore(new Date())
            ? "Published"
            : "Scheduled"
          : "Draft",
    },
    {
      fieldId: "publishAt",
      label: "Published At",
      render: (data) =>
        moment(new Date(data.publishAt!)).format("MMM DD, YYYY"),
    },
    {
      fieldId: "author",
      label: "Author",
      render: (data) => (data.author ? data.author.name : "------"),
    },
    {
      fieldId: "visit",
      label: "Visit",
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<MdEdit />}
            onClick={() => navigate(`${data.id}`)}
          >
            Edit
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdDelete />}
            onClick={() => handleDelete(data.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <ContentContainer>
        <PinnedProductSection />
      </ContentContainer>
      <ContentContainer>
        <div className="flex items-center justify-between mb-2">
          <div className="flex flex-col">
            <h3 className="text-2xl text-[#262626] font-semibold">
              Product Management
            </h3>
            <div className="flex flex-row gap-4">
              <p className="text-xs font-bold text-gray-600">{data?.meta.total} items</p>
              <p className="text-xs font-bold text-gray-600">{data?.meta.lastPage} pages</p>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <SearchInput
              placeholder="Search"
              onSubmit={({ text }) => {
                setParams((prev) => ({ ...prev, search: text, page: 1 }));
              }}
            />
            <Button
              onClick={() => {
                navigate("/product/create");
              }}
              className="bg-san-juan text-white hover:bg-san-juan/90"
            >
              Create Product
            </Button>
          </div>
        </div>
        <Table<ProductI>
          data={data?.data}
          columns={header}
          loading={isLoading}
          currentPage={params.page}
          limit={params.limit}
        />
        <div className="mt-6">
          <Pagination
            currentPage={data?.meta.page ?? 0}
            totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
            onPageChange={(next) =>
              setParams((prev) => ({ ...prev, page: next }))
            }
          />
        </div>
      </ContentContainer>
    </div>
  );
};

export default ProductPage;
