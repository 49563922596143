import { ArticleI, GetArticlesQuery } from "_interfaces/article.interfaces";
import { HelpI } from "_interfaces/help.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import ContentContainer from "components/container";
import DeletePopUp from "components/modal/other/Delete";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import { Button } from "react-daisyui";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import {
  useDeleteArticleMutation,
  useGetArticlesQuery,
} from "services/modules/article";
import { useGetHelpQuery } from "services/modules/help";

export const helpRouterName = "help-content";
const HelpPage = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, refetch } = useGetHelpQuery(params);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<{
    open: boolean;
    id?: number;
  }>({ open: false });
  const [deleteArticle] = useDeleteArticleMutation();

  const header: Columns<HelpI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Title",
    },
    {
      fieldId: "createdAt",
      label: "Posted At",
      render: (data) => moment(data.createdAt).format("MMM D, YYYY"),
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) =>
      (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<MdEdit />}
            onClick={() => navigate(`/help-content/update/${data.name}`)}
          >
            Edit
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdDelete />}
            onClick={() => handleDeletePopUp(data.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleDeletePopUp = (id?: number) => {
    setIsDeletePopupOpen((prev) => ({ open: !prev.open, id }));
  };

  const handleDeleteArticle = async (): Promise<void> => {
    try {
      // await deleteArticle(isDeletePopupOpen.id!).unwrap();
      handleDeletePopUp();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Help Content Management
        </h3>
        <div className="flex flex-row gap-2">
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setParams((prev) => ({ ...prev, search: text }));
            }}
          />
          <Button
            onClick={() => {
              navigate("/help-content/create");
            }}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Create Content
          </Button>
        </div>
      </div>
      <Table<HelpI>
        data={data?.data}
        columns={header}
        loading={isLoading}
      />
      <DeletePopUp
        isOpen={isDeletePopupOpen.open}
        data={"Help Content"}
        onClose={handleDeletePopUp}
        onEdit={async () => {
          handleDeleteArticle();
        }}
        menu="Help Content"
      />
    </ContentContainer>
  );
};

export default HelpPage;
