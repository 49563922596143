import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateCategoryMutation } from "services/modules/crafter";
import { errorHandler } from "services/errorHandler";
import { CouponPayloadI } from "_interfaces/coupon.interfaces";
import { useCreateCouponMutation, useUpdateCouponMutation } from "services/modules/coupon";

const useCouponUpsert = (id?: string) => {
  const [createCoupon, stateCreate] = useCreateCouponMutation();
  const [updateCoupon, stateUpdate] = useUpdateCouponMutation();
  const isLoading = stateCreate.isLoading || stateUpdate.isLoading;
  const isSuccess = stateCreate.isSuccess || stateUpdate.isSuccess;
  const schema = yup
    .object({
      name: yup.string().required(),
      code: yup.string().matches(/^\S+$/, 'Space not allowed').required(),
      percentage: yup.number().required().min(0).max(100),
      expiredAt: yup.date().required(),
    })
    .required();
  const defaultValues: CouponPayloadI = {
    name: "",
    code: "",
    percentage: 0,
    expiredAt: new Date(),
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
    reset,
  } = useForm<CouponPayloadI>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const create = async (data: CouponPayloadI) => {
    try {
      await createCoupon(data).unwrap();
    } catch (error) {
      errorHandler(error);
    }
  };

  const update = async (data: CouponPayloadI) => {
    try {
      await updateCoupon({ id: id!, body: data }).unwrap();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCreate = handleSubmit(create);
  const handleUpdate = handleSubmit(update);

  return {
    handleCreate,
    handleUpdate,
    isLoading,
    register,
    errors,
    watch,
    defaultValues,
    control,
    isSuccess,
    reset,
  };
};

export default useCouponUpsert;
