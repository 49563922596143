import { CategoryPerformanceI, ProductPerformanceI } from "_interfaces/transaction.interfaces";
import { Order, SortBy } from "_interfaces/user.interfaces";
import ContentContainer from "components/container";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import {
  FaDollarSign,
  FaDownload,
  FaFileInvoiceDollar,
  FaUser,
  FaUserFriends,
} from "react-icons/fa";
import { useGetAllCategoryPerformanceQuery, useGetAllProductPerformanceQuery } from "services/modules/transaction";
import { useGetDownloadSummaryQuery, useGetTransactionSummaryQuery, useGetUsersQuery, useGetUserThisMonthQuery } from "services/modules/user";

export const dashboardRouteName = "dashboard";
const DashboardPage = () => {
  const { data: userThisMonth } = useGetUserThisMonthQuery(undefined);
  const { data: users } = useGetUsersQuery({ page: 1, limit: 1, order: Order.DESC, sortBy: SortBy.createdat })
  const { data: downloadSummary } = useGetDownloadSummaryQuery(undefined);
  const { data: transactionSummary } = useGetTransactionSummaryQuery(undefined);
  const { data: categoryPerformance } = useGetAllCategoryPerformanceQuery(undefined);
  const { data: productPerformance } = useGetAllProductPerformanceQuery(undefined);

  const totalTransaction = transactionSummary?.total.reduce((prev, cur) => prev + cur.price, 0);
  const totalSuccess = transactionSummary?.success.reduce((prev, cur) => prev + cur.price, 0);
  const totalFailed = transactionSummary?.failed.reduce((prev, cur) => prev + cur.price, 0);

  const categoryHeader: Columns<CategoryPerformanceI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "category",
      label: "Category",
    },
    {
      fieldId: "count",
      label: "Order",
    },
    {
      fieldId: "price",
      label: "Net Sales",
      render: (item) => `$${(item.price / 100).toLocaleString('en-US', { currency: "USD" })}`
    },
  ]

  const productHeader: Columns<ProductPerformanceI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Category",
    },
    {
      fieldId: "count",
      label: "Order",
    },
    {
      fieldId: "price",
      label: "Net Sales",
      render: (item) => `$${(item.price / 100).toLocaleString('en-US', { currency: "USD" })}`
    },
  ]

  const thisMonth = moment(new Date()).format("MMMM");
  return (
    <>
      <h3 className="text-2xl text-[#262626] font-semibold">Dashboard</h3>
      <div className="grid grid-cols-3 gap-6 mt-6">
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDollarSign
              className="text-blue-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">Orders</div>
              <div className="text-lg font-bold text-blue-600">
                ${totalTransaction?.toLocaleString("en", { currency: "USD" })}{" "}
                <div className="inline-flex text-sm text-gray-600">({thisMonth})</div>
              </div>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDollarSign
              className="text-green-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">
                Success Orders
              </div>
              <div className="text-lg font-bold text-green-600">
                ${totalSuccess?.toLocaleString("en", { currency: "USD" })}{" "}
                <div className="inline-flex text-sm text-gray-600">({thisMonth})</div>
              </div>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDollarSign
              className="text-red-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">
                Failed Orders
              </div>
              <div className="text-lg font-bold text-red-600">
                ${totalFailed?.toLocaleString("en", { currency: "USD" })}{" "}
                <div className="inline-flex text-sm text-gray-600">({thisMonth})</div>
              </div>
            </div>
          </div>
        </ContentContainer>
      </div>
      <div className="grid grid-cols-3 grid-flow-row-dense items-start gap-6 mt-6">
        <div className="flex flex-col gap-6">
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaUserFriends
                className="text-green-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  Total Users
                </div>
                <div className="text-lg font-bold text-green-600">{users?.meta.total ?? 0}</div>
              </div>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaUserFriends
                className="text-yellow-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  New Users
                </div>
                <div className="text-lg font-bold text-yellow-600">
                  {userThisMonth?.data ?? 0}{" "}
                  <div className="inline-flex text-sm text-gray-600">
                    {`(${thisMonth})`}
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>
        <ContentContainer>
          <div className="flex flex-row items-center gap-6">
            <FaDownload
              className="text-blue-600"
              size={50}
            />
            <div>
              <div className="text-2xl font-bold text-gray-600">Downloads</div>
              <div className="text-lg font-bold text-blue-600">{downloadSummary ?? 0}</div>
            </div>
          </div>
        </ContentContainer>
        <div className="flex flex-col gap-6">
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaFileInvoiceDollar
                className="text-green-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  Active Membership
                </div>
                <div className="text-lg font-bold text-green-600">300,000</div>
              </div>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div className="flex flex-row items-center gap-6">
              <FaFileInvoiceDollar
                className="text-yellow-600"
                size={50}
              />
              <div>
                <div className="text-2xl font-bold text-gray-600">
                  New Membership
                </div>
                <div className="text-lg font-bold text-yellow-600">
                  700{" "}
                  <div className="inline-flex text-sm text-gray-600">
                    (April)
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>
      </div>
      <div className="grid grid-cols-5 mt-5 gap-2">
        <div className="col-span-2 overflow-y-scroll max-h-[400px]">
          <Table<CategoryPerformanceI>
            columns={categoryHeader}
            data={categoryPerformance?.categories}
          />
        </div>
        <div className="max-w-full overflow-x-scroll overflow-y-scroll max-h-[400px] col-span-3">
          <Table<ProductPerformanceI>
            columns={productHeader}
            data={productPerformance?.products}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
