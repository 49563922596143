import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CategoryFormI,
  CategoryFormUpdateI,
  CategoryPayloadI,
} from "_interfaces/category.interfaces";
import { useUpdateCategoryMutation } from "services/modules/crafter";
import { uploadFile } from "services/modules/file";
import { useAppSelector } from "store";
import { errorHandler } from "services/errorHandler";

const useUpdateCategoryForm = (id?: number) => {
  const [updateCategory, stateUpdate] = useUpdateCategoryMutation();
  const { accessToken } = useAppSelector((state) => state.auth);
  const schema = yup
    .object({
      name: yup.string().required(),
      description: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
    setValue,
    reset,
  } = useForm<CategoryFormUpdateI>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const update = async (data: CategoryFormUpdateI) => {
    try {
      const { name, description, image } = data;
      const categoryId = data.categoryId
        ? +data.categoryId.value.split("-")[0]
        : undefined;
      const subCategoryId = data.subCategoryId
        ? +data.subCategoryId.value.split("-")[0]
        : undefined;
      const payload: CategoryPayloadI = { name, description, image: image.url, categoryId, subCategoryId };
      if (image.file) {
        const fileUrl = await uploadFile(accessToken!, image.file![0]);
        payload.image = fileUrl
      }
      updateCategory({ body: payload, id: id! });
    } catch (error) {
      errorHandler(error);
    }
  }

  const handleUpdate = handleSubmit(update);

  return {
    handleUpdate,
    register,
    errors,
    watch,
    control,
    setValue,
    stateUpdate,
    reset,
  };
};

export default useUpdateCategoryForm;
