import { GetAffiliateResI } from "_interfaces/affiliate.interfaces";
import {
  CouponPayloadI,
  GetCouponReqI,
  GetCouponResI,
} from "_interfaces/coupon.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import { Api } from "services/api";

export const couponApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getCoupon: build.query<GetCouponResI, GetCouponReqI>({
      query: (params) => ({ url: "/billing/admin/coupon", params }),
      keepUnusedDataFor: 0,
    }),
    createCoupon: build.mutation<void, CouponPayloadI>({
      query: (body) => ({
        url: `/billing/admin/coupon`,
        method: "POST",
        body,
      }),
    }),
    updateCoupon: build.mutation<void, { id: string; body: CouponPayloadI }>({
      query: ({ id, body }) => ({
        url: `/billing/admin/coupon/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteCoupon: build.mutation<void, string>({
      query: (id) => ({
        url: `/billing/admin/coupon/${id}`,
        method: "DELETE",
      }),
    }),
    reject: build.mutation<void, string>({
      query: (id) => ({
        url: `/billing/admin/coupon/${id}/reject`,
        method: "POST",
      }),
    }),
    accept: build.mutation<void, string>({
      query: (id) => ({
        url: `/billing/admin/coupon/${id}/accept`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCouponQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
  useRejectMutation,
  useAcceptMutation,
} = couponApi;

