import { Navigate, RouteObject } from "react-router-dom";
import Login from "pages/login";
import DashboardLayout from "layout/dashboard";
import DashboardPage, { dashboardRouteName } from "pages/dashboard/index.page";
import NotFoundPage from "pages/not-found/index.page";
import UserPage, { userManagementRouteName } from "pages/user/index.page";
import TransactionPage, {
  transactionRouteName,
} from "pages/transaction/index.page";
import CategoryPage, {
  categoryManagementRouteName,
} from "pages/category/index.page";
import ProductPage, {
  productManagementRouteName,
} from "pages/product/index.page";
import ArticlePage, {
  articleManagementRouteName,
} from "pages/article/index.page";
import UserDetailPage, { userDetailRouteName } from "pages/user/detail.page";
import CreateProduct, {
  createProductRouteName,
  updateProductRouteName,
} from "pages/product/create.page";
import CollaboratorPage, {
  collaboratorRouteName,
} from "pages/collaborator/index.page";
import ArticleForm, {
  createArticleRouteName,
  updateArticleRouteName,
} from "pages/article/form.page";
import AffiliatePage, { affiliateManagementRouteName } from "pages/affiliate";
import DumpPage, { dumpRouteName } from "pages/dump/index.page";
import CreateDumPage, { createDumpRouteName } from "pages/dump/create.page";
import ThemePage, { themeManagementRouteName } from "pages/theme/index.page";
import { Campaign } from "pages/campaign";
import CreatorPage, { creatorManagementRouteName } from "pages/creator/index.page";
import CampaignUser from "pages/campaign-user";
import MessageHistory from "pages/message-history";
import CrafterProjectPage, { crafterProjectPageRouteName } from "pages/crafter-project/index.page";
import CouponPage, { CouponManagementRouteName } from "pages/coupon/index.page";
import StoryPage, { storyManagementRouteName } from "pages/story/index.page";
import SupportMessagePage, { supportMessagePageRouter } from "pages/support-message/index.page";
import HelpPage, { helpRouterName } from "pages/help/index.page";
import HelpForm, { createHelpRouteName, updateHelpRouteName } from "pages/help/form.page";
import AffiliateDetailPage, { affiliateDetailRouteName } from "pages/affiliate/detail.page";

const protectedRoutesCollaborator: RouteObject[] = [
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      { path: "*", element: <NotFoundPage /> },
      {
        path: "article",
        children: [
          {
            path: articleManagementRouteName,
            element: <ArticlePage />,
            index: true,
          },
          { path: createArticleRouteName, element: <ArticleForm /> },
          { path: updateArticleRouteName, element: <ArticleForm /> },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: productManagementRouteName,
            element: <ProductPage />,
            index: true,
          },
          { path: createProductRouteName, element: <CreateProduct /> },
          { path: updateProductRouteName, element: <CreateProduct /> },
        ],
      },
      {
        path: "category",
        children: [
          {
            path: categoryManagementRouteName,
            element: <CategoryPage />,
            index: true,
          },
        ],
      },
    ],
  },
];

const protectedRoutesAdmin: RouteObject[] = [
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      { path: "*", element: <NotFoundPage /> },
      { path: dashboardRouteName, element: <DashboardPage />, index: true },
      { path: affiliateManagementRouteName, element: <AffiliatePage /> },
      { path: affiliateDetailRouteName, element: <AffiliateDetailPage /> },
      { path: crafterProjectPageRouteName, element: <CrafterProjectPage /> },
      { path: CouponManagementRouteName, element: <CouponPage /> },
      { path: storyManagementRouteName, element: <StoryPage /> },
      { path: supportMessagePageRouter, element: <SupportMessagePage /> },
      { path: helpRouterName, element: <HelpPage /> },
      { path: createHelpRouteName, element: <HelpForm /> },
      { path: updateHelpRouteName, element: <HelpForm /> },
      {
        path: "user",
        children: [
          { path: userManagementRouteName, element: <UserPage />, index: true },
          { path: userDetailRouteName, element: <UserDetailPage /> },
        ],
      },
      {
        path: "category",
        children: [
          {
            path: categoryManagementRouteName,
            element: <CategoryPage />,
            index: true,
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: productManagementRouteName,
            element: <ProductPage />,
            index: true,
          },
          { path: createProductRouteName, element: <CreateProduct /> },
          { path: updateProductRouteName, element: <CreateProduct /> },
        ],
      },
      {
        path: "collaborator",
        children: [
          {
            path: collaboratorRouteName,
            element: <CollaboratorPage />,
            index: true,
          },
        ],
      },
      {
        path: "article",
        children: [
          {
            path: articleManagementRouteName,
            element: <ArticlePage />,
            index: true,
          },
          { path: createArticleRouteName, element: <ArticleForm /> },
          { path: updateArticleRouteName, element: <ArticleForm /> },
        ],
      },
      {
        path: "dump",
        children: [
          {
            path: dumpRouteName,
            element: <DumpPage />,
            index: true,
          },
          { path: createDumpRouteName, element: <CreateDumPage /> },
        ],
      },
      {
        path: "theme",
        children: [
          {
            path: themeManagementRouteName,
            element: <ThemePage />,
            index: true,
          },
        ],
      },
      {
        path: "creator",
        children: [
          {
            path: creatorManagementRouteName,
            element: <CreatorPage />,
            index: true,
          },
        ],
      },
      {
        path: "transaction",
        children: [
          {
            path: transactionRouteName,
            element: <TransactionPage />,
            index: true,
          },
        ],
      },
      {
        path: "campaign",
        children: [
          {
            path: "",
            element: <Campaign />,
            index: true,
          },
        ],
      },
      {
        path: "campaign-user",
        children: [
          {
            path: "",
            element: <CampaignUser />,
            index: true,
          },
        ],
      },
      {
        path: "campaign-messages",
        children: [
          {
            path: "",
            element: <MessageHistory />,
            index: true,
          },
        ],
      },
    ],
  },
];

const publicRoutes: RouteObject[] = [
  { path: "", element: <Login /> },
  { path: "404", element: <NotFoundPage /> },
  { path: "*", element: <Navigate to="/" /> },
];

export { publicRoutes, protectedRoutesAdmin, protectedRoutesCollaborator };
