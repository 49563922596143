import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import { Button, Modal } from "react-daisyui";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useGetSupportMessageQuery, useProceedSupportMessageMutation } from "services/modules/auth";
import { SupportMessageI, SupportMessageReqI } from "_interfaces/support-message.interfaces";
import Pagination from "components/table/pagination";
import { FaEye } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

export const supportMessagePageRouter = "support-message";
const SupportMessagePage = () => {
  const [params, setParams] = useState<SupportMessageReqI>({ page: 1, limit: 10, status: "pending" });
  const { data, isLoading } = useGetSupportMessageQuery(params);
  const [proceed, { isLoading: proceedLoading }] = useProceedSupportMessageMutation();
  const [showDetail, setShowDetail] = useState<{ show: boolean; message?: string }>({ show: false });
  const [search, setSearch] = useState('');
  const header: Columns<SupportMessageI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Name",
    },
    {
      fieldId: "email",
      label: "Email",
    },
    {
      fieldId: "subject",
      label: "Subject",
    },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<FaEye />}
            onClick={() => {
              setShowDetail({
                show: true,
                message: data.message
              });
            }}
          >
            Show Message
          </Button>
          <Button
            variant="outline"
            color="info"
            startIcon={<MdEdit />}
            onClick={() => proceed(data.id)}
            loading={proceedLoading}
          >
            Mark Processed
          </Button>
        </div>
      ),
    },
  ];

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Unread Support Message
        </h3>
        <div className="flex flex-row gap-2">
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setSearch(text);
            }}
          />
        </div>
      </div>
      <Table
        data={data?.data}
        columns={header}
        loading={isLoading}
      />
      <div className="mt-6">
        <Pagination
          currentPage={data?.meta.page ?? 0}
          totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
          onPageChange={(next) =>
            setParams((prev) => ({ ...prev, page: next }))
          }
        />
      </div>

      <Modal
        className="bg-white w-1/3 max-w-[900px]"
        open={showDetail.show}
      >
        <Modal.Header className="flex flex-row justify-between">
          Message
          <IoClose onClick={() => setShowDetail({ show: false })} />
        </Modal.Header>
        <Modal.Body className="overflow-scroll">
          {showDetail.message}
        </Modal.Body>
      </Modal>
    </ContentContainer>
  );
};

export default SupportMessagePage;
