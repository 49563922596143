import { DumpI } from "./dump.interfaces";
import { Meta, PagingDTO } from "./pagination.interface";

export enum ProjectStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

export interface GetProjectQuery extends PagingDTO {
  status: ProjectStatus;
}

export interface GetProjectResI {
  data: ProjectI[];
  meta: Meta;
}

export interface ProjectI {
  id: string;
  name?: string;
  description: string;
  imageUrl: string;
  createdAt: Date;
  updatedAt: Date;
  likeCount: number;
  price: number;
  status: ProjectStatus;
  coin: number;
  reason?: string;
  product: Product[];
  breezy: DumpI[];
  user: User;
  likes: User[];
}

export interface Product {
  id: string;
  name: string;
  imageUrl: string[];
  description: string;
  category: string;
  categories: string[];
  subCategories: string[];
  chilSubCategories: string[];
  tags: string[];
  purchasedCount: number;
  price: number[];
  coinPrice: number[];
  enableDiscount: boolean;
  discount: number[];
  discountPeriod: string;
  fileType: string;
  fileSize: number;
  postType: string;
  publishAt: string;
  createdAt: string;
  updatedAt: Date;
  deletedAt: Date;
  authorId: Date;
}

export interface User {
  id: string;
  email: string;
  username: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  blocked: boolean;
  avatar: string;
}

