import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import { Button, Select } from "react-daisyui";
import { useState } from "react";
import { MdBlock, MdCheck, MdDelete, MdEdit } from "react-icons/md";
import { useAcceptMutation, useDeleteCouponMutation, useGetCouponQuery, useRejectMutation } from "services/modules/coupon";
import { CouponI, CouponStatus, GetCouponReqI } from "_interfaces/coupon.interfaces";
import moment from "moment";
import UpsertCouponModal from "./modal/create.modal";
import { errorHandler } from "services/errorHandler";
import Pagination from "components/table/pagination";

export const CouponManagementRouteName = "coupon";
const CouponPage = () => {
  const [params, setParams] = useState<GetCouponReqI>({
    page: 1,
    limit: 10,
    status: CouponStatus.accepted,
  });
  const {
    data,
    isLoading,
    refetch,
  } = useGetCouponQuery(params);
  const [deleteCoupon, deleteState] = useDeleteCouponMutation();
  const [rejectCoupon, rejectState] = useRejectMutation();
  const [acceptCoupon, acceptState] = useAcceptMutation();
  const [showCreate, setShowCreate] = useState<{ show: boolean; data?: CouponI }>({ show: false });
  const [search, setSearch] = useState('');

  const handleReject = async (id: string) => {
    try {
      await rejectCoupon(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  }

  const handleApprove = async (id: string) => {
    try {
      await acceptCoupon(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  }

  const deleteData = async (id: string) => {
    try {
      await deleteCoupon(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  }
  const header: Columns<CouponI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Coupon Name",
    },
    { fieldId: "code", label: "Code" },
    { fieldId: "code", label: "Creted By", render: (item) => item.authorId ? item.author.user.username : 'Admin' },
    { fieldId: "code", label: "Discount", render: (item) => `${item.percentage}%` },
    { fieldId: "expiredAt", label: "Expired Date", render: (item) => moment(item.expiredAt).format("MMMM DD, YYYY") },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => params.status === CouponStatus.accepted ? (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<MdEdit />}
            onClick={() => {
              setShowCreate({ show: true, data })
            }}
            disabled={deleteState.isLoading}
          >
            Edit
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdDelete />}
            onClick={() => deleteData(data.id)}
            disabled={deleteState.isLoading}
          >
            Delete
          </Button>
        </div>
      )
        :
        (
          <div className="flex flex-row justify-center gap-2">
            <Button
              variant="outline"
              color="info"
              startIcon={<MdCheck />}
              onClick={() => {
                handleApprove(data.id);
              }}
              disabled={acceptState.isLoading || rejectState.isLoading}
            >
              Approve
            </Button>
            <Button
              variant="outline"
              color="error"
              startIcon={<MdBlock />}
              onClick={() => handleReject(data.id)}
              disabled={acceptState.isLoading || rejectState.isLoading}
            >
              Reject
            </Button>
          </div>
        ),
    },
  ];

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Coupon Management
        </h3>
        <div className="flex flex-row gap-2">
          <Select value={params.status} onChange={(e) => setParams(prev => ({ ...prev, page: 1, status: (e.target.value as CouponStatus) }))}>
            <option value={CouponStatus.accepted}>Accepted</option>
            <option value={CouponStatus.pending}>Pending</option>
            <option value={CouponStatus.rejected}>Rejected</option>
          </Select>
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setParams(prev => ({ ...prev, search: text }));
            }}
          />
          <Button
            onClick={() => setShowCreate({ show: true })}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Create Coupon
          </Button>
        </div>
      </div>
      <Table
        data={data?.coupons}
        columns={header}
        loading={isLoading}
      />
      <Pagination
        currentPage={params.page}
        totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
        onPageChange={(page) => {
          setParams((prev) => ({ ...prev, page }));
        }}
      />
      <UpsertCouponModal
        open={showCreate.show}
        handleClose={(needRefetch) => {
          setShowCreate({ show: false });
          if (needRefetch) {
            refetch();
          }
        }}
        data={showCreate.data}
      />
    </ContentContainer>
  );
};

export default CouponPage;
