import { ManagementType } from "_interfaces/collaborator.interfaces";

interface SubMenuItem {
  name: string;
  path: string;
}
interface MenuItem {
  name: string;
  path: string;
  child?: SubMenuItem[];
  expand?: boolean;
  role: ManagementType[];
}

const menuItems: MenuItem[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    role: [ManagementType.Admin],
  },
  {
    name: "Collaborator Management",
    path: "/collaborator",
    role: [ManagementType.Admin],
  },
  {
    name: "User Management",
    path: "/user",
    role: [ManagementType.Admin],
  },
  {
    name: "Project Management",
    path: "/crafter-project",
    role: [ManagementType.Admin],
  },
  {
    name: "Story Management",
    path: "/story",
    role: [ManagementType.Admin],
  },
  {
    name: "Coupon Management",
    path: "/coupon",
    role: [ManagementType.Admin],
  },
  {
    name: "Affiliate Management",
    path: "/affiliate",
    role: [ManagementType.Admin],
  },
  {
    name: "Category Management",
    path: "/category",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Product Management",
    path: "/product",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Article Management",
    path: "/article",
    role: [ManagementType.Admin, ManagementType.Collaborator],
  },
  {
    name: "Help Support",
    path: "#",
    role: [ManagementType.Admin, ManagementType.Collaborator],
    child: [
      {
        name: "Help Content",
        path: "/help-content",
      },
      {
        name: "Messages",
        path: "/support-message",
      },
    ],
  },
  {
    name: "Transaction",
    path: "/transaction",
    role: [ManagementType.Admin],
  },
  {
    name: "Breezy",
    path: "#",
    role: [ManagementType.Admin, ManagementType.Collaborator],
    child: [
      {
        name: "Drizy Breezy",
        path: "/dump",
      },
      {
        name: "Drizy Breezy Theme",
        path: "/theme",
      },
      {
        name: "Drizy Breezy Creator",
        path: "/creator",
      },
    ],
  },
  {
    name: "Campaign",
    path: "#",
    role: [ManagementType.Admin],
    child: [
      {
        name: "Campaign Users",
        path: "/campaign-user",
      },
      {
        name: "Campaign Messages",
        path: "/campaign-messages",
      },
    ]
  },
];

export { menuItems };
export type { MenuItem };
