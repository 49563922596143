import { CollaboratorI } from "./collaborator.interfaces";
import { PagingDTO } from "./pagination.interface";

export interface UserI {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  lastLoginAt: string;
  membership?: MembershipI;
  block: boolean;
}

export interface PurchaseI {
  id: string;
  status: "SUCCESS" | "FAILED" | "PENDING";
  paymentURL: string;
  order: OrderI;
  createdAt: string;
  user?: string;
}

export interface OrderI {
  productId: string;
  productName: string;
  price: number;
}

export interface MembershipI {
  endedAt: string;
  type: string;
}

export interface DownloadI {
  id: string;
  product: ProductI;
  createdAt: string;
}

export interface GetProductResI {
  data: ProductI[];
  meta: Meta;
}

export interface ProductI {
  id: string;
  name: string;
  imageUrl: string[];
  description: string;
  category: string;
  categories: string[];
  subCategories: string[];
  chilSubCategories: string[];
  tags: string[];
  purchasedCount: number;
  price: number[];
  enableDiscount: boolean;
  discount: number[];
  discountPeriod: string;
  fileType: string;
  fileSize: number;
  createdAt: string;
  updatedAt: any;
  meta: MetadataSEO[];
  author?: CollaboratorI;
  postType: PostType;
  publishAt?: string;
  visit: number;
}

export interface MetadataSEO {
  id: string;
  title: string;
  realTitle: string;
  description: string;
  image: string;
  productId: string;
}

export interface Meta {
  total: number;
  page: number;
  limit: number;
  lastPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export enum SortType {
  HighToLow = "HighToLow",
  LowToHigh = "LowToHigh",
  Popularity = "Popularity",
  Latest = "Latest",
}

export enum PostType {
  Publish = "Publish",
  Draft = "Draft",
}

export interface GetProductQuery extends PagingDTO {
  category?: string;
  sortType: SortType;
}

export interface ProductPayloadI {
  name: string;
  description: string;
  productUrl: string;
  category: string[];
  subCategory: string[];
  childSubCategory: string[];
  tags: string[];
  price: number[];
  coinPrice: number[];
  enableDiscount: boolean;
  discountPeriod: string;
  discount: number[];
  imageUrl: string[];
  fileType: string;
  fileSize: number;
  metaTitle: string;
  metaDescription: string;
  postType: PostType;
  publishAt?: string;
}

export interface ProductFormI {
  name: string;
  description: string;
  product: FileType;
  category: string[];
  subCategory: string[];
  childSubCategory: string[];
  tags: { text: string }[];
  price: number[];
  coinPrice: number[];
  enableDiscount: boolean;
  discount: number[];
  discountPeriod: string;
  image: FileType[];
  fileType: string;
  fileSize: number;
  metaTitle: string;
  metaDescription: string;
  postType: PostType;
  publishAt?: string;
}

export interface ImageFileType {
  file?: File;
  url?: string;
}

export interface FileType {
  file?: FileList;
  url?: string;
}

export interface GetProductByIdRes {
  data: ProductWithMetaI;
}

export interface ProductWithMetaI {
  id: string;
  title: string;
  realTitle: string;
  description: string;
  image: string;
  productId: string;
  product: ProductDataI;
}

export interface ProductDataI {
  id: string;
  name: string;
  imageUrl: string[];
  description: string;
  category: string;
  url: string;
  categories: string[];
  subCategories: any[];
  chilSubCategories: string[];
  tags: string[];
  purchasedCount: number;
  price: number[];
  coinPrice: number[];
  enableDiscount: boolean;
  discount: number[];
  discountPeriod: string;
  fileType: string;
  fileSize: number;
  createdAt: string;
  updatedAt: any;
  publishAt?: string;
  postType: PostType;
}

export interface PinnedProductResI {
  pinnedProduct: PinnedProduct
}

export interface PinnedProduct {
  id: number
  productId: string
  product: ProductDataI
}
