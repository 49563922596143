import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCreateHelpMutation, useUpdateHelpMutation } from "services/modules/help";
import { CreateHelpI } from "_interfaces/help.interfaces";

const useHelpForm = (id?: number) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createHelp] = useCreateHelpMutation();
  const [updateHelp] = useUpdateHelpMutation();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Title cannot empty")
      .max(60, "Title cannot more than 255 char")
      .min(5, "Title cannot less than 5 char"),
    description: yup
      .string()
      .required("Content cannot empty")
      .min(100, "Content cannot less than 100 char"),
    group: yup.string().required('Type empty, please choose any type'),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    setValue,
    reset,
  } = useForm<CreateHelpI>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  console.log(errors);

  const create = async (data: CreateHelpI) => {
    try {
      setIsLoading(true);
      await createHelp(data).unwrap();
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: CreateHelpI) => {
    try {
      setIsLoading(true);
      await updateHelp({ id: id!, body: data }).unwrap();
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = handleSubmit(create);
  const handleUpdate = handleSubmit(update);

  return {
    handleCreate,
    handleUpdate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    setValue,
    reset,
  };
};

export default useHelpForm;
