import { AffiliateI } from "_interfaces/affiliate.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import ContentContainer from "components/container";
import CInput from "components/input";
import SearchInput from "components/search-input";
import Pagination from "components/table/pagination";
import { Columns, Table } from "components/table/table";
import moment from "moment";
import { useState } from "react";
import { Button, Modal } from "react-daisyui";
import { MdCheck, MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import {
  useApproveMutation,
  useGetAffiliateRequestQuery,
  useRejectMutation,
} from "services/modules/affiliate";

export const affiliateManagementRouteName = "affiliate";
const PendingAfilliateSection = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const { data, isLoading, refetch } = useGetAffiliateRequestQuery(params);
  const [approve, approveState] = useApproveMutation();
  const [reject, rejectState] = useRejectMutation();
  const [approveData, setApproveData] = useState<{ show: boolean, commissionRate?: number, id?: string }>({ show: false });
  const header: Columns<AffiliateI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "id",
      label: "User Name",
      render: (item) => (
        <div
          className="underline text-blue-500 cursor-pointer"
          onClick={() => navigate(`/user/${item.userId}`)}
        >
          {item.user.displayName}
        </div>
      ),
    },
    {
      fieldId: "id",
      label: "User Email",
      render: (item) => item.user.email,
    },
    {
      fieldId: "status",
      label: "Status",
      render: (item) => item.status.toUpperCase(),
    },
    {
      fieldId: "createdAt",
      label: "Requested At",
      render: (item) => moment(item.createdAt).format("MMM D, YYYY"),
    },
    {
      fieldId: "id",
      label: "Action",
      render: (item) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="success"
            startIcon={<MdCheck />}
            onClick={() => setApproveData(prev => ({ ...prev, show: true, id: item.id }))}
            disabled={item.status === "accepted" || item.status === "rejected"}
          >
            Accept
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdClose />}
            onClick={() => handleReject(`${item.id}`)}
            disabled={item.status === "accepted" || item.status === "rejected"}
            loading={rejectState.isLoading}
          >
            Reject
          </Button>
        </div>
      ),
    },
  ];

  const handleApprove = async () => {
    try {
      await approve({
        id: approveData.id!,
        commissionRate: approveData.commissionRate!,
      }).unwrap();
      setApproveData({ show: false });
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleReject = async (id: string) => {
    try {
      await reject(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <div className="flex items-center justify-end mb-6">
        <SearchInput
          placeholder="Search"
          onSubmit={({ text }) => {
            setParams((prev) => ({ ...prev, search: text }));
          }}
        />
      </div>
      <Table<AffiliateI>
        data={data?.data}
        columns={header}
        loading={isLoading}
        currentPage={params.page}
        limit={params.limit}
      />
      <div className="mt-6">
        <Pagination
          currentPage={data?.meta.page ?? 0}
          totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
          onPageChange={(next) =>
            setParams((prev) => ({ ...prev, page: next }))
          }
        />
      </div>

      <Modal
        open={approveData.show}
        backdrop={false}
        className="flex flex-col justify-center items-center bg-white"
      >
        <Modal.Header className="flex flex-col items-center">
          <svg
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="68"
              height="68"
              rx="34"
              fill="#BAFBD0"
              fillOpacity="0.2"
            />
            <path
              d="M34 54C45.0457 54 54 45.0457 54 34C54 22.9543 45.0457 14 34 14C22.9543 14 14 22.9543 14 34C14 45.0457 22.9543 54 34 54Z"
              fill="#27A590"
              fillOpacity="0.2"
              stroke="#27A590"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M42 34L34 26L26 34"
              stroke="#27A590"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34 42V26"
              stroke="#27A590"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="53" cy="8" r="2" fill="#27A590" />
            <circle cx="7" cy="47" r="2" fill="#27A590" />
            <path
              d="M12.9996 10.2679L12.9996 13.732L9.99963 12L12.9996 10.2679Z"
              fill="#1A857D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M61 37C60.996 35.8968 60.1021 35.0037 59 35.0037C60.1046 35.0037 61 34.1066 61 33C61.004 34.1032 61.8979 34.9963 63 34.9963C61.8954 34.9963 61 35.8934 61 37Z"
              fill="#1A857D"
            />
          </svg>

          <div className="font-bold mt-4 text-base">
            Approve Afilliate
          </div>
        </Modal.Header>
        <Modal.Body className="w-full">
          <CInput
            className="w-[80%]"
            placeholder="Commission Rate"
            value={approveData.commissionRate}
            onChange={(e) => setApproveData(prev => ({ ...prev, commissionRate: Number(e.target.value) }))}
          />
        </Modal.Body>
        <Modal.Actions className="flex w-full flex-col items-center">
          <Button
            type="submit"
            className="w-[80%] rounded-full bg-persian-green hover:bg-persian-green/90"
            onClick={handleApprove}
            loading={approveState.isLoading}
          >
            Yes
          </Button>
          <Button
            type="button"
            className="w-[80%] rounded-full text-red-600 mt-2 hover:bg-white/90"
            onClick={() => {
              setApproveData({ show: false })
            }}
            loading={approveState.isLoading}
          >
            No
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default PendingAfilliateSection;
