import { PagingDTO } from "_interfaces/pagination.interface";
import {
  GetAffiliateAllEarningI,
  GetAffiliateMonthlyEarningI,
  GetCategoryPerformanceResI,
  GetProductPerformanceResI,
  GetTransactionResI,
} from "_interfaces/transaction.interfaces";
import { Api } from "services/api";

export const transactionApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<GetTransactionResI, PagingDTO>({
      query: (params) => ({
        url: "billing/admin/user-transactions",
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getUserTransactions: build.query<
      GetTransactionResI,
      { id: string; params: PagingDTO }
    >({
      query: ({ id, params }) => ({
        url: `billing/admin/user-transactions/${id}`,
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getAllCategoryPerformance: build.query<
      GetCategoryPerformanceResI,
      undefined
    >({
      query: () => ({
        url: `billing/admin/product-categories-count`,
      }),
      keepUnusedDataFor: 0,
    }),
    getAllProductPerformance: build.query<GetProductPerformanceResI, undefined>(
      {
        query: () => ({
          url: `billing/admin/product-count`,
        }),
        keepUnusedDataFor: 0,
      },
    ),
    getAffiliateCategoryPerformance: build.query<
      GetCategoryPerformanceResI,
      string
    >({
      query: (id) => ({
        url: `billing/admin/affiliate-product-categories-count/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getAffiliateProductPerformance: build.query<
      GetProductPerformanceResI,
      string
    >({
      query: (id) => ({
        url: `billing/admin/affiliate-product-count/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getAffiliateAllEarning: build.query<GetAffiliateAllEarningI, string>({
      query: (id) => ({
        url: `billing/admin/affiliate-all-earning/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
    getAffiliateMonthlyEarning: build.query<
      GetAffiliateMonthlyEarningI,
      string
    >({
      query: (id) => ({
        url: `billing/admin/affiliate-monthly-earning/${id}`,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTransactionsQuery,
  useGetUserTransactionsQuery,
  useGetAllCategoryPerformanceQuery,
  useGetAllProductPerformanceQuery,
  useGetAffiliateCategoryPerformanceQuery,
  useGetAffiliateMonthlyEarningQuery,
  useGetAffiliateAllEarningQuery,
  useGetAffiliateProductPerformanceQuery,
} = transactionApi;

