import { AffiliateI } from "./affiliate.interfaces";
import { Meta, PagingDTO } from "./pagination.interface";

export enum CouponStatus {
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
}
export interface GetCouponReqI extends PagingDTO {
  status: CouponStatus;
}

export interface GetCouponResI {
  coupons: CouponI[];
  meta: Meta;
}

export interface CouponI {
  id: string;
  name: string;
  code: string;
  percentage?: number;
  discount?: number;
  expiredAt: Date;
  status: string;
  authorId: any;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
  author: AffiliateI
}

export interface CouponPayloadI {
  name: string;
  code: string;
  percentage: number;
  expiredAt: Date | string;
}

