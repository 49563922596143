import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "store";
import { uploadFile } from "services/modules/file";
import { ProductFormI, ProductPayloadI } from "_interfaces/post.interface";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from "services/modules/crafter";
import moment from "moment";
import { imageCompressor } from "_helper/image-compressor";

const useCreatePostForm = (id?: string) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createPost] = useCreateProductMutation();
  const [updatePost] = useUpdateProductMutation();
  const { accessToken } = useAppSelector((state) => state.auth);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Product name cannot empty")
      .max(60, "Product name cannot more than 255 char")
      .min(5, "Product name cannot less than 5 char"),
    description: yup
      .string()
      .required("Description cannot empty")
      .min(200, "Description name cannot less than 200 char"),
    product: yup.object().shape({
      file: yup
        .mixed()
        .required("Product downloadable cannot empty, please select any file"),
    }),
    coinPrice: yup
      .array()
      .of(
        yup
          .number()
          .min(0, "Price must be greater than or equal 0")
          .required("Price cannot empy")
          .typeError("Price cannot empy"),
      ),
    price: yup
      .array()
      .of(
        yup
          .number()
          .min(0, "Price must be greater than or equal 0")
          .required("Price cannot empy")
          .typeError("Price cannot empy"),
      ),
    discount: yup.array().of(
      yup
        .number()
        .typeError("Discount cannot empy")
        .min(0, "Discount must be greater than or equal 0")
        .when("enableDiscount", {
          is: true,
          then: yup.number().required("Discount cannot empty"),
        }),
    ),
    discountPeriod: yup.string().when("enableDiscount", {
      is: true,
      then: yup.string().required("Discount Period cannot empty"),
    }),
    fileType: yup.string().required("Fiel type cannot empty"),
    fileSize: yup
      .number()
      .min(1, "File size must be greater than or equal 1")
      .required("Fiel size cannot empty")
      .typeError("File size must be a number"),
    publishAt: yup.date().min(moment(new Date()).format("YYYY-MM-DD"), "Must be later than now"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setFocus,
    watch,
    setValue,
    reset,
  } = useForm<ProductFormI>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      image: [{ url: "" }],
      product: { url: "" },
      discount: [0, 0, 0],
      category: [],
      subCategory: [],
      childSubCategory: [],
      enableDiscount: false,
      discountPeriod: moment(new Date()).format("YYYY-MM-DD"),
      publishAt: moment(new Date()).format("YYYY-MM-DD"),
    },
  });

  const create = async (data: ProductFormI) => {
    try {
      setIsLoading(true);
      let payload: ProductPayloadI = {
        name: data.name,
        description: data.description,
        productUrl: "",
        category: data.category,
        subCategory: data.subCategory,
        childSubCategory: data.childSubCategory,
        tags: data.tags.map((item) => item.text),
        price: data.price,
        coinPrice: data.coinPrice,
        discount: data.discount,
        imageUrl: [],
        enableDiscount: data.enableDiscount,
        discountPeriod: data.discountPeriod,
        fileType: data.fileType,
        fileSize: data.fileSize,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        publishAt: data.publishAt,
        postType: data.postType,
      };
      if (data.product.file) {
        const file = await uploadFile(
          accessToken!,
          data.product.file[0],
        );

        payload.productUrl = file;
      }
      const promisesCompress: Promise<any>[] = [];
      const promises: Promise<string>[] = [];
      data.image.forEach((item, i) => {
        if (item.file && item.file[0]) {
          promisesCompress.push(imageCompressor(item.file[0]));
          // promises.push(uploadFile(accessToken!, item.file[0]));
        }
      });
      if (promisesCompress.length > 0) {
        const compressedImages: File[] = await Promise.all(promisesCompress);
        if (compressedImages.length > 0) {
          compressedImages.forEach(item => {
            promises.push(uploadFile(accessToken!, item));
          });
          const images = await Promise.all(promises);
          let temp: string[] = [];
          images.forEach((item) => {
            temp.push(item);
          });
          payload.imageUrl = temp;
        }
      }
      await createPost(payload).unwrap();
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: ProductFormI) => {
    try {
      setIsLoading(true);
      let payload: ProductPayloadI = {
        name: data.name,
        description: data.description,
        productUrl: data.product.url!,
        category: data.category,
        subCategory: data.subCategory,
        childSubCategory: data.childSubCategory,
        tags: data.tags.map((item) => item.text),
        price: data.price,
        coinPrice: data.coinPrice,
        discount: data.discount,
        imageUrl: data.image.map((item) => item.url!),
        enableDiscount: data.enableDiscount,
        discountPeriod: data.discountPeriod,
        fileType: data.fileType,
        fileSize: data.fileSize,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        publishAt: data.publishAt,
        postType: data.postType,
      };
      if (data.product.file && data.product.file[0]) {
        const file = await uploadFile(
          accessToken!,
          data.product.file[0] as File,
        );

        payload.productUrl = file;
      }
      const promisesCompress: Promise<any>[] = [];
      const promises: Promise<string>[] = [];
      const newImage: number[] = [];
      const changes = data.image.map((item, index) => ({ ...item, index })).filter(item => item.file && item.file[0]);
      changes.forEach((item, i) => {
        if (item.file && item.file[0]) {
          newImage.push(i);
          promisesCompress.push(imageCompressor(item.file[0]));
          // promises.push(uploadFile(accessToken!, item.file[0]));
        }
      });
      if (promisesCompress.length > 0) {
        const compressedImages: File[] = await Promise.all(promisesCompress);
        if (compressedImages.length > 0) {
          compressedImages.forEach(item => {
            promises.push(uploadFile(accessToken!, item));
          });
        }
        const images = await Promise.all(promises);
        changes.forEach((c, k) => {
          payload.imageUrl[c.index] = images[k];
        });
      }
      await updatePost({ id: id!, body: payload }).unwrap();
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = handleSubmit(create);
  const handleUpdate = handleSubmit(update);

  return {
    handleCreate,
    handleUpdate,
    register,
    errors,
    setFocus,
    control,
    isLoading,
    watch,
    setValue,
    reset,
  };
};

export default useCreatePostForm;
