import { Meta, PagingDTO } from "./pagination.interface";

export interface GetLinkResI {
  data: LinkI[];
  meta: Meta;
}

export interface LinkI {
  id: string;
  originalUrl: string;
  shortUrl: string;
  createdAt: string;
  clickCount: number;
  affiliateId: string;
  affiliate: Affiliate;
  clicks: Click[];
}

export interface Affiliate {
  id: string;
  refferalCode: string;
  totalEarnings: number;
  commissionRate: number;
  eligible: boolean;
  userId: string;
  createdAt: string;
  updatedAt: any;
  user: User;
}

export interface Click {
  id: string;
  clickDate: string;
  ipAddress: string;
  userAgent: string;
}

export enum SortBy {
  transactioncount = "transactioncount",
  shortlinkclickcount = "shortlinkclickcount",
  downloadcount = "downloadcount",
  createdat = "createdat",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export interface GetAffiliateReqI extends PagingDTO {
  sortBy: SortBy;
  order: Order;
}

export interface GetAffiliatorResI {
  data: AfilliatorI[];
  meta: MetaAffiliate;
}

export interface AfilliatorI {
  id: string;
  refferalCode: string;
  totalEarnings: number;
  commissionRate: number;
  eligible: boolean;
  userId: string;
  createdAt: string;
  updatedAt: any;
  user: User;
  clickCountSum: number;
  transactionCount: number;
  downloadCount: number;
}

export interface GetAffiliateResI {
  data: AffiliateI[];
  meta: MetaAffiliate;
}

export interface AffiliateI {
  id: string;
  userId: string;
  status: string;
  rejectReason?: string;
  createdAt: string;
  user: User;
}

export interface User {
  id: string;
  email: string;
  username: string;
  displayName: string;
  createdAt: string;
  affiliateId?: string;
}

export interface MetaAffiliate {
  total: number;
  page: number;
  limit: number;
  lastPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

