import { ProjectI, ProjectStatus } from "_interfaces/crafter-project.interfaces";
import CInput from "components/input";
import { useEffect, useState } from "react";
import { Button, Select } from "react-daisyui";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import { useApproveProjectMutation, useGetProjectByIdQuery, useRejectProjectMutation } from "services/modules/crafter-project";

const ProjectDetail = ({ id, isOpen, closeModal }: { id: string; isOpen: boolean; closeModal: (refetch: boolean) => void }) => {
  const { data } = useGetProjectByIdQuery(id);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<{ type: "approve" | "reject", coin?: number, reason?: string }>({ type: "approve" });
  const [approveProject, approveProjectState] = useApproveProjectMutation();
  const [rejectProject, rejectProjectState] = useRejectProjectMutation();
  const projectData = data?.data;

  const handleSubmit = async () => {
    try {
      if (formData.type === "approve") {
        await approveProject({ id: projectData?.id!, coin: formData.coin! }).unwrap();
        closeModal(true);
      } else {
        await rejectProject({ id: projectData?.id!, reason: formData.reason! }).unwrap();
        closeModal(true);
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  return (
    <div>
      {/* Modal overlay */}
      {isOpen && (
        <div
          onClick={() => { closeModal(false) }}
          className='fixed left-0 top-0 z-30 h-full w-full bg-black bg-opacity-50'
        ></div>
      )}

      {/* Modal content */}
      {isOpen && projectData && (
        <div className='fixed left-0 top-0 z-30 transform overflow-hidden rounded-3xl bg-white shadow-lg max-md:w-full lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2'>
          <div className='flexn  w-full flex-col max-md:overflow-y-scroll h-fit lg:w-[888px]'>
            <div className='flex flex-row-reverse items-center justify-between bg-[#E5F6FB] px-4 py-2'>
              <IoCloseCircleOutline
                onClick={() => { closeModal(false) }}
                className='text-[#4065D1]'
                size={30}
              />
              <p className='pl-[7%] font-semibold text-[#1A214C]'>
                Project Details
              </p>
              <div />
            </div>
            <div className='flex flex-col gap-4 p-4 lg:flex-row lg:gap-8 lg:p-8'>
              <div className='flex flex-col items-center justify-center gap-4 lg:w-1/2'>
                <div className='max-h-screen w-full items-center justify-center rounded-xl lg:h-[310px] lg:w-[432px]'>
                  <div className='!important flex h-full w-full items-center justify-center'>
                    <img
                      src={projectData?.imageUrl}
                      alt='slider'
                      className='w-full px-2'
                    />
                  </div>
                </div>
                <div className='flex w-full justify-between'>
                  <div onClick={() => { navigate(`/user/${projectData.user.id}`) }} className='flex items-center text-[14px] text-[#1A204C] cursor-pointer'>
                    <img
                      loading='lazy'
                      src={projectData?.user.avatar}
                      className=' mr-3 w-[39px]'
                    />
                    <div className='mr-1'>By</div>
                    <div className='font-katide-bold'>
                      {projectData?.user.displayName}
                    </div>
                  </div>
                </div>
                {projectData.status === ProjectStatus.Pending &&
                  <div className="grid grid-cols-2 gap-4">
                    <Select className="w-full" value={formData?.type} onChange={(e) => setFormData({ type: e.target.value as "approve" | "reject" })}>
                      <option>Approval</option>
                      <option value="approve">Approve</option>
                      <option value="reject">Reject</option>
                    </Select>
                    <CInput
                      type={formData?.type === "approve" ? "number" : "text"}
                      placeholder={formData?.type === "approve" ? "Coin Reward" : "Reason"}
                      onChange={(e) => {
                        if (formData?.type === "approve") {
                          setFormData(prev => ({ ...prev, coin: Number(e.target.value) }))
                        } else {
                          setFormData(prev => ({ ...prev, reason: e.target.value }))
                        }
                      }}
                    />
                    <Button
                      className="w-full col-span-2"
                      onClick={handleSubmit}
                      loading={approveProjectState.isLoading || rejectProjectState.isLoading}
                      disabled={approveProjectState.isLoading || rejectProjectState.isLoading}
                    >
                      Save
                    </Button>
                  </div>
                }
              </div>
              <div className='flex flex-col gap-2 lg:gap-4'>
                <p className='font-katide-regular text-[14px]'>
                  {projectData?.description}
                </p>
                <div className='font-katide-semibold mt-5 text-[14px] lg:mt-10'>
                  {projectData?.product.map(item =>
                    <div key={item.id} onClick={() => navigate(`/product/${item.id}`)} className='flex w-full items-center gap-5 border-t max-md:justify-between cursor-pointer'>
                      <p className='mt-3 hover:underline'>Download {item.name}</p>
                      <FaArrowUpRightFromSquare className='mt-2 text-[#61A9FA]' />
                    </div>
                  )}
                  {projectData?.breezy.map(item =>
                    <a key={item.id} href={`https://breezy.drizycraft.com/${item.id}`} target='__blank' className='flex w-full items-center gap-5 border-t max-md:justify-between cursor-pointer'>
                      <p className='mt-3 hover:underline'>Download {item.title}</p>
                      <FaArrowUpRightFromSquare className='mt-2 text-[#61A9FA]' />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProjectDetail;