import { GetProductQuery, SortType } from "_interfaces/post.interface";
import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import { errorHandler } from "services/errorHandler";
import { useGetPinnedProductQuery, useGetProductQuery, useUpdatePinnedProductMutation } from "services/modules/crafter";
import ReactSelect from 'react-select';
import useDebounce from "hooks/shared/useDebounce";

const PinnedProductSection = () => {
  const { data, refetch } = useGetPinnedProductQuery(undefined);
  const [update] = useUpdatePinnedProductMutation();
  const [search, setSearch] = useState("");
  const text = useDebounce(search, 1000);

  const [params, setParams] = useState<GetProductQuery>({
    page: 1,
    limit: 10,
    sortType: SortType.Latest,
    search: '',
  });
  const { data: productData } = useGetProductQuery({ ...params, search: text }, { skip: !data });

  const handleChange = async (id?: string) => {
    try {
      if (id) {
        await update(id).unwrap();
        refetch();
        toast.success('Pinned Product Updated');
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  return data ?
    <div className="mb-2">
      <label className="font-semibold">Pinned Product</label>
      <div className="flex flex-row items-center gap-2">
        <div className="w-20 h-20 flex items-center justify-center">
          <img className="object-contain content-center" src={data.pinnedProduct.product.imageUrl[0]} />
        </div>
        <p>{data.pinnedProduct.product.name}</p>
      </div>
      <div className="w-1/4">
        <ReactSelect onChange={(e) => handleChange(e?.value)} onInputChange={setSearch} options={productData?.data.map(item => ({ label: item.name, value: item.id }))} />
      </div>
    </div>
    : null
}

export default PinnedProductSection;