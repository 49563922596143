import { CategoryPerformanceI } from "_interfaces/transaction.interfaces";
import ContentContainer from "components/container";
import { Columns, Table } from "components/table/table";
import { useGetAffiliateCategoryPerformanceQuery } from "services/modules/transaction";

const CategoryPerformanceSection = ({ id }: { id: string }) => {
  const { data, isLoading, isFetching } = useGetAffiliateCategoryPerformanceQuery(id!, { skip: !id });

  const categoryHeader: Columns<CategoryPerformanceI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "category",
      label: "Category",
    },
    {
      fieldId: "count",
      label: "Order",
    },
    {
      fieldId: "price",
      label: "Net Sales",
      render: (item) => `$${(item.price / 100).toLocaleString('en-US', { currency: "USD" })}`
    },
  ];

  return (
    <ContentContainer>
      <h3 className="text-xl text-[#262626] font-semibold mb-4">
        Category Performance
      </h3>
      <div className="w-full overflow-auto">
        <Table<CategoryPerformanceI>
          data={data?.categories}
          loading={isLoading || isFetching}
          columns={categoryHeader}
        />
      </div>
    </ContentContainer>
  );
};

export default CategoryPerformanceSection;
