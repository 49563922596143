import { CategoryI } from "_interfaces/category.interfaces";
import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import { Button } from "react-daisyui";
import CreateCategory from "./modal/create.modal";
import { useEffect, useState } from "react";
import {
  useGetCategoriesQuery,
  useGetChildCategoriesQuery,
  useGetSubCategoriesQuery,
} from "services/modules/crafter";
import { MdDelete, MdEdit } from "react-icons/md";
import UpdateCategory from "./modal/update.modal";

export const categoryManagementRouteName = "";
const CategoryPage = () => {
  const {
    data: category,
    isLoading: loadingCategory,
    refetch: refetchCategory,
  } = useGetCategoriesQuery(undefined);
  const {
    data: subCategory,
    isLoading: loadingSubCategory,
    refetch: refetchSubCategory,
  } = useGetSubCategoriesQuery(undefined);
  const {
    data: childCategory,
    isLoading: loadingChildCategory,
    refetch: refetchChildCategory,
  } = useGetChildCategoriesQuery(undefined);
  const [dataView, setDataView] = useState<CategoryI[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<{ show: boolean; data?: CategoryI }>({ show: false });
  const [search, setSearch] = useState('');
  const header: Columns<CategoryI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "name",
      label: "Category Name",
    },
    {
      fieldId: "id",
      label: "Parent",
      render: (data) => {
        if (data.categoryId) {
          return (
            category?.data.find((item) => item.id === data.categoryId)?.name ??
            ""
          );
        }
        if (data.subCategoryId) {
          return (
            subCategory?.data.find((item) => item.id === data.subCategoryId)
              ?.name ?? ""
          );
        }
        return "";
      },
    },
    { fieldId: "id", label: "pinned", render: () => "" },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<MdEdit />}
            onClick={() => {
              setShowEdit({
                show: true,
                data,
              });
            }}
          >
            Edit
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdDelete />}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (category?.data && subCategory?.data && childCategory?.data) {
      const temp = [
        ...(category?.data ?? []),
        ...(subCategory?.data ?? []),
        ...(childCategory?.data ?? []),
      ];
      temp.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });
      setDataView(temp.filter(item => item.name.toLowerCase().includes(search.toLowerCase())));
    }
  }, [category?.data, subCategory?.data, childCategory?.data, search]);

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Category Management
        </h3>
        <div className="flex flex-row gap-2">
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setSearch(text);
            }}
          />
          <Button
            onClick={() => setShowCreate(true)}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Create Category
          </Button>
        </div>
      </div>
      <Table
        data={dataView}
        columns={header}
      />
      <CreateCategory
        open={showCreate}
        handleClose={(needRefetch) => {
          setShowCreate(false);
          if (needRefetch) {
            refetchCategory();
            refetchSubCategory();
            refetchChildCategory();
          }
        }}
        categories={category?.data ?? []}
        subCategories={subCategory?.data ?? []}
      />
      <UpdateCategory
        open={showEdit.show}
        data={showEdit.data}
        handleClose={(needRefetch) => {
          setShowEdit({ show: false });
          if (needRefetch) {
            refetchCategory();
            refetchSubCategory();
            refetchChildCategory();
          }
        }}
        categories={category?.data ?? []}
        subCategories={subCategory?.data ?? []}
      />
    </ContentContainer>
  );
};

export default CategoryPage;
