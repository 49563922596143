import {
  CreateArticlePayload,
  GetArticleByIdRes,
  GetArticlesQuery,
  GetArticlesResI,
} from "_interfaces/article.interfaces";
import { CreateHelpI } from "_interfaces/help.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import { Api } from "services/api";

export const helpApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getHelp: build.query<any, PagingDTO>({
      query: (params) => {
        return {
          url: "/auth/admin/help",
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getHelpByName: build.query<any, string>({
      query: (id) => `/auth/admin/help/${id}`,
      keepUnusedDataFor: 0,
    }),
    createHelp: build.mutation<void, CreateHelpI>({
      query(body) {
        return {
          url: `/auth/admin/help`,
          method: "POST",
          body,
        };
      },
    }),
    updateHelp: build.mutation<
      void,
      { id: number; body: CreateHelpI }
    >({
      query({ body, id }) {
        return {
          url: `/auth/admin/help/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetHelpQuery,
  useGetHelpByNameQuery,
  useCreateHelpMutation,
  useUpdateHelpMutation,
} = helpApi;
