import React from "react";
import { FileInput, Modal } from "react-daisyui";
import { IoTrash } from "react-icons/io5";

export default function ImageInput({
  register,
  isWide,
  imagePreview,
  first,
  wideImage,
  handleDelete,
  multiple = false,
}: {
  register: any;
  isWide?: boolean;
  wideImage?: boolean;
  imagePreview: string | undefined;
  first: boolean;
  handleDelete?: () => void;
  multiple?: boolean;
}): React.ReactElement {
  return (
    <div
      className={`w-full border-[#BDBDBD] border rounded-lg flex flex-col text-center items-center justify-center p-10 gap-3 relative ${isWide ? "col-span-2" : "col-span-1"
        }`}
    >
      {handleDelete &&
        <div className="cursor-pointer absolute top-1 right-1 bg-white/40 p-2 rounded-full" onClick={handleDelete}><IoTrash /></div>
      }
      {imagePreview ? (
        <div className="flex flex-col items-center gap-2">
          <img
            className={`flex mx-auto ${wideImage ? "w-full h-[250px]" : "w-[300] h-[200px]"} object-contain`}
            src={imagePreview}
            alt=""
          />
          {first && (
            <p className="text-xs text-gray-500">{`(Product Image)`}</p>
          )}
        </div>
      ) : (
        <div className="text-san-juan">
          Choose your {first ? `product image` : `image`} here
        </div>
      )}
      <FileInput
        className="max-w-full"
        {...register}
        size="sm"
        multiple={multiple}
        accept="image/*"
      />
    </div>
  );
}
