import { CategoryI } from "_interfaces/category.interfaces";
import CInput from "components/input";
import Select from "components/select";
import ValidationError from "components/validation/error";
import useCategoryForm from "hooks/category/useCategoryForm";
import useUpdateCategoryForm from "hooks/category/useUpdateCategoryForm";
import useFilePreview from "hooks/shared/useFilePreview";
import { useEffect } from "react";
import { Button, Modal } from "react-daisyui";
import { Controller } from "react-hook-form";
import { IoClose } from "react-icons/io5";

const UpdateCategory = ({
  open,
  handleClose,
  categories,
  subCategories,
  data,
}: {
  open: boolean;
  handleClose: (refetch?: boolean) => void;
  categories: CategoryI[];
  subCategories: CategoryI[];
  data?: CategoryI;
}) => {
  const { handleUpdate, errors, register, setValue, stateUpdate, watch, reset } =
    useUpdateCategoryForm(data?.id);
  const bgImage = watch("image.file");
  const [bgImagePreview] = useFilePreview(bgImage);
  const categoryOptions = categories.map((item) => ({
    label: item.name,
    value: `${item.id}-parent`,
  }));
  const subCategoryOptions = subCategories.map((item) => ({
    label: item.name,
    value: `${item.id}-sub`,
  }));

  useEffect(() => {
    if (stateUpdate.isSuccess) {
      handleClose(true);
    }
  }, [stateUpdate.isSuccess]);

  useEffect(() => {
    if (data) {
      const categoryId = [...categoryOptions, ...subCategoryOptions].find(option => {
        return (Number(option.value.replace("-parent", "")) === data.categoryId)
      });
      const subCategoryId = [...categoryOptions, ...subCategoryOptions].find(option => {
        return (Number(option.value.replace("-sub", "")) === data.subCategoryId)
      });
      reset({
        name: data.name,
        description: data.description,
        categoryId,
        subCategoryId,
        image: {
          url: data.backgroundImage,
        },
      });
    }
  }, [data?.id]);

  return (
    <Modal
      className="bg-white w-1/3 max-w-[900px]"
      open={open}
    >
      <Modal.Header className="flex flex-row justify-between">
        Update Category
        <IoClose onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body className="overflow-scroll">
        <form
          className="p-2 flex flex-col gap-4"
          onSubmit={handleUpdate}
        >
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Parent
            </label>
            <Select
              disabled={true}
              value={watch("subCategoryId.value") || watch("categoryId.value")}
              rounded={true}
              options={[...categoryOptions, ...subCategoryOptions]}
              onChange={(e) => {
                if (e.value.includes("parent")) {
                  setValue("categoryId", e);
                  setValue("subCategoryId", undefined);
                } else {
                  setValue("subCategoryId", e);
                  setValue("categoryId", undefined);
                }
              }}
            />
          </div>
          <div>
            <label
              htmlFor="merchantID"
              className="font-semibold text-[#262626]"
            >
              Category Name
            </label>
            <CInput
              {...register("name")}
              type="text"
            />
            <ValidationError error={errors.name} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Description
            </label>
            <CInput {...register("description")} />
            <ValidationError error={errors.description} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Image
            </label>
            {bgImagePreview ? (
              <img
                className="flex mx-auto w-[100px] h-[100px] object-fill mb-2"
                src={bgImagePreview}
                alt=""
              />
            ) : (
              <img className="flex mx-auto w-[100px] h-[100px] object-fill mb-2" src={data?.backgroundImage} />
            )}
            <CInput
              {...register("image.file")}
              type="file"
            />
            <ValidationError error={errors.image?.file} />
          </div>
          <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
            <Button
              type="reset"
              className="border-seeds text-seeds"
              onClick={() => handleClose()}
              loading={stateUpdate.isLoading}
              disabled={stateUpdate.isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan/90"
              loading={stateUpdate.isLoading}
              disabled={stateUpdate.isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateCategory;
