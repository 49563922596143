import { CategoryI } from "_interfaces/category.interfaces";
import { StoryI } from "_interfaces/story.interfaces";
import CInput from "components/input";
import ImageInput from "components/post/ImageInput";
import Select from "components/select";
import ValidationError from "components/validation/error";
import useCategoryForm from "hooks/category/useCategoryForm";
import useFilePreview from "hooks/shared/useFilePreview";
import useStoryUpsert from "hooks/story/useStoryUpsert";
import { useEffect } from "react";
import { Button, Modal } from "react-daisyui";
import { Controller, useFieldArray } from "react-hook-form";
import { IoAdd, IoClose } from "react-icons/io5";

const UpsertStory = ({
  open,
  handleClose,
  data,
}: {
  open: boolean;
  handleClose: (refetch?: boolean) => void;
  data?: StoryI;
}) => {
  const { handleCreate, handleUpdate, errors, register, isSuccess, isLoading, watch, control, defaultItems, reset } =
    useStoryUpsert(data?.id);
  const { fields, remove, append } = useFieldArray({
    control,
    name: "items",
  });
  const image = watch("items");
  const imagePreview = image.map(({ url: { file } }) => {
    if (file && file[0]) {
      return URL.createObjectURL(file[0]);
    }
  });

  useEffect(() => {
    if (isSuccess) {
      handleClose(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data) {
      reset({
        title: data.title,
        description: data.description,
        items: data.storyItem.map(item => ({ url: { url: item.url }, actionUrl: item.actionUrl, id: item.id }))
      });
    }
  }, [data]);

  return (
    <Modal
      className="bg-white w-1/3 max-w-[900px]"
      open={open}
    >
      <Modal.Header className="flex flex-row justify-between">
        Create New Story
        <IoClose onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body className="overflow-scroll">
        <form
          className="p-2 flex flex-col gap-4"
          onSubmit={data? handleUpdate : handleCreate}
        >
          <div>
            <label
              htmlFor="merchantID"
              className="font-semibold text-[#262626]"
            >
              Story Title
            </label>
            <CInput
              {...register("title")}
              type="text"
            />
            <ValidationError error={errors.title} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Description
            </label>
            <CInput {...register("description")} />
            <ValidationError error={errors.description} />
          </div>
          <div className="grid grid-cols-2 gap-5 items-stretch">
            {fields.map((item, i) => (
              <div className="flex flex-col gap-1 w-full" key={i}>
                <ImageInput
                  first={false}
                  isWide={fields.length % 2 === 1 && i === fields.length - 1}
                  imagePreview={imagePreview[i] ?? item.url.url}
                  register={register(`items.${i}.url.file`)}
                  handleDelete={() => remove(i)}
                />
                <CInput placeholder="Action URL" {...register(`items.${i}.actionUrl`)} />
              </div>
            ))}
          </div>
          <Button type="button" onClick={() => append(defaultItems)} startIcon={<IoAdd />}>Add Story Image</Button>
          <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
            <Button
              type="reset"
              className="border-seeds text-seeds"
              onClick={() => handleClose()}
              loading={isLoading}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan/90"
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpsertStory;
