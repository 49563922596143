import ContentContainer from "components/container";
import moment from "moment";
import NotFoundPage from "pages/not-found/index.page";
import { Badge } from "react-daisyui";
import { useParams } from "react-router-dom";
import {
  useGetUserByIdQuery,
} from "services/modules/user";
import { useGetAffiliateAllEarningQuery, useGetAffiliateMonthlyEarningQuery } from "services/modules/transaction";
import CategoryPerformanceSection from "./sections/category-performance.section";
import ProductPerformanceSection from "./sections/product-performance.section";
import { useGetAffiliateLinksQuery } from "services/modules/affiliate";
import { Columns, Table } from "components/table/table";
import { LinkI } from "_interfaces/affiliate.interfaces";

export const affiliateDetailRouteName = "affiliate/:id";
const AffiliateDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isError } = useGetUserByIdQuery(id!, { skip: !id });
  const { data: monthlyEarning } = useGetAffiliateMonthlyEarningQuery(id!, { skip: !id });
  const { data: allEarning } = useGetAffiliateAllEarningQuery(id!, { skip: !id })
  const { data: links } = useGetAffiliateLinksQuery({ id: id!, params: { page: 1, limit: 10 } }, { skip: !id });

  const linkHeader: Columns<LinkI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "shortUrl",
      label: "Url",
      render: (item) => `https://link.drizycraft.com/${item.shortUrl}`,
    },
    {
      fieldId: "originalUrl",
      label: "Product Url",
      render: (item) => <a href={item.originalUrl.split("?ref")[0]} target="_blank">Product</a>
    },
    {
      fieldId: "clickCount",
      label: "Visit",
    },
  ]

  if (isError) return <NotFoundPage />;
  return (
    <>
      <ContentContainer>
        <div className="flex flex-row gap-2 items-center mb-4">
          <h3 className="text-2xl text-[#262626] font-semibold">Affiliate Detail</h3>
          {data?.blocked ? (
            <Badge
              size="lg"
              className="text-white"
              color="error"
            >
              Blocked
            </Badge>
          ) : (
            <Badge
              size="lg"
              className="text-white"
              color="success"
            >
              Active
            </Badge>
          )}
        </div>
        <div className="w-full grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            <label className="text-lg">Display Name</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.displayName}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">Email</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.email}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">First Name</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.username}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">Last Login At</label>
            <div className="font-semibold text-base text-gray-700">
              {moment(data?.updatedAt).format("HH:mm MMM D, YYYY")}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">Last Name</label>
            <div className="font-semibold text-base text-gray-700">
              {data?.username}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-lg">{moment(new Date()).format("MMMM")} Earning / All Earning</label>
            <div className="font-semibold text-base text-gray-700 capitalize">
              ${monthlyEarning?.sum.toLocaleString("en-US", { currency: "USD" })} / ${allEarning?.sum.toLocaleString("en-US", { currency: "USD" })}
            </div>
          </div>
        </div>
      </ContentContainer>
      <div className="mt-4 grid grid-cols-2 gap-4">

      </div>
      <div className="mt-4 grid grid-cols-5 gap-4">
        <div className="col-span-2 overflow-y-scroll max-h-[400px]">
          <CategoryPerformanceSection id={id!} />
        </div>
        <div className="col-span-3 max-w-full overflow-x-scroll overflow-y-scroll max-h-[400px]">
          <ProductPerformanceSection id={id!} />
        </div>
        <div className="col-span-2 overflow-y-scroll max-h-[400px]">
          <ContentContainer>
            <h3 className="text-xl text-[#262626] font-semibold mb-4">
              Top 10 Affiliate Link
            </h3>
            <Table<LinkI>
              columns={linkHeader}
              data={links?.data}
            />
          </ContentContainer>
        </div>
      </div>
    </>
  );
};

export default AffiliateDetailPage;
