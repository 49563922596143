import { PagingDTO } from "./pagination.interface";
import { ProductI } from "./post.interface";

export enum SortBy {
  successtransaction = "successtransaction",
  failedtransaction = "failedtransaction",
  spend = "spend",
  coinspend = "coinspend",
  createdat = "createdat",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export interface GetDataUserDTO extends PagingDTO {
  sortBy: SortBy;
  order: Order;
}
export interface GetUsersResI {
  data: User[];
  meta: Meta;
}

export interface User {
  id: string;
  email: string;
  username: string;
  displayName?: string;
  password?: string;
  activationKey: any;
  userStatus: any;
  coin: any;
  createdAt: string;
  updatedAt?: string;
  blocked: boolean;
  affiliateId?: string;
  avatar?: string;
  gid?: string;
  fid?: string;
  subscriptions: Subscription[];
  successTransactions: number;
  failedTransactions: number;
  spend: number;
  coinSpend: number;
}

export interface Subscription {
  id: number;
  coin: number;
  userId: string;
  subsId: string;
  startedAt: string;
  endedAt: string;
}

export interface AffiliateI {
  id: string;
  refferalCode: string;
  totalEarnings: number;
  userId: string;
  createdAt: Date;
  updatedAt?: Date;
}

export interface Meta {
  total: number;
  page: number;
  limit: number;
  lastPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface GetDownloadResI {
  data: DownloadI[];
  meta: Meta;
}

export interface DownloadI {
  id: string;
  userId: string;
  user: User;
  productId: string;
  product: ProductI;
  createdAt: Date;
}

