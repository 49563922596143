import ContentContainer from "components/container";
import AfilliatorSection from "./sections/afilliator";
import PendingAfilliateSection from "./sections/pending";
import { useState } from "react";

export const affiliateManagementRouteName = "affiliate";
const AffiliatePage = () => {
  const [active, setActive] = useState(0);
  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Affiliate Management
        </h3>
        <div className="flex flex-row gap-0">
          <div onClick={() => setActive(0)} className={`${active === 0 ? "border-b-2" : "border-b"} border-blue-gray-400 cursor-pointer px-4 py-2 text-center`}>
            Pending Request
          </div>
          <div onClick={() => setActive(1)} className={`${active === 1 ? "border-b-2" : "border-b"} border-blue-gray-400 cursor-pointer px-4 py-2 text-center`}>
            Active Afilliators
          </div>
        </div>
      </div>
      {active === 0 ?
        <PendingAfilliateSection />
        :
        <AfilliatorSection />
      }
    </ContentContainer>
  );
};

export default AffiliatePage;
