import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorHandler } from "services/errorHandler";
import { CouponPayloadI } from "_interfaces/coupon.interfaces";
import { useCreateCouponMutation, useUpdateCouponMutation } from "services/modules/coupon";
import { useCreateStoryMutation, useUpdateStoryMutation } from "services/modules/story";
import { CreateStoryFormI, CreateStoryPayloadI, StoryItemFormI, StoryItemPayloadI } from "_interfaces/story.interfaces";
import { imageCompressor } from "_helper/image-compressor";
import { uploadFile } from "services/modules/file";
import { useAppSelector } from "store";

const useStoryUpsert = (id?: string) => {
  const [createCoupon, stateCreate] = useCreateStoryMutation();
  const [updateCoupon, stateUpdate] = useUpdateStoryMutation();
  const isLoading = stateCreate.isLoading || stateUpdate.isLoading;
  const isSuccess = stateCreate.isSuccess || stateUpdate.isSuccess;
  const { accessToken } = useAppSelector((state) => state.auth);
  const schema = yup
    .object({
      title: yup.string().required(),
      description: yup.string().required(),
      items: yup.array().of(yup.object({
        url: yup.object({
          url: yup.string().optional(),
          file: yup.mixed().optional(),
        }),
        actionUrl: yup.string().optional(),
      })),
    })
    .required();
  const defaultItems: StoryItemFormI = {
    url: {
      file: undefined,
    },
  };
  const defaultValues: CreateStoryFormI = {
    title: "",
    description: "",
    items: [],
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
    reset,
  } = useForm<CreateStoryFormI>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const create = async (data: CreateStoryFormI) => {
    try {
      const payload: CreateStoryPayloadI = {
        title: data.title,
        description: data.description,
        items: []
      };
      const promisesCompress: Promise<any>[] = [];
      const promises: Promise<string>[] = [];
      data.items.forEach((item, i) => {
        if (item.url.file && item.url.file[0]) {
          promisesCompress.push(imageCompressor(item.url.file[0]));
        }
      });
      if (promisesCompress.length > 0) {
        const compressedImages: File[] = await Promise.all(promisesCompress);
        if (compressedImages.length > 0) {
          compressedImages.forEach(item => {
            promises.push(uploadFile(accessToken!, item));
          });
          const images = await Promise.all(promises);
          const storyItems: StoryItemPayloadI[] = images.map((item, i) => {
            return {
              url: item,
              actionUrl: data.items[i].actionUrl,
            }
          });
          payload.items = storyItems;
        }
      }
      await createCoupon(payload).unwrap();
    } catch (error) {
      errorHandler(error);
    }
  };

  const update = async (data: CreateStoryFormI) => {
    try {
      const payload: CreateStoryPayloadI = {
        title: data.title,
        description: data.description,
        items: []
      };
      const updatesOrNew: number[] = [];
      const tempItems = data.items.map((item, i) => {
        if (!item.id || item.url.file?.[0]) updatesOrNew.push(i);
        return {
          id: item.id,
          url: item.url.url,
          actionUrl: item.actionUrl,
        }
      });
      payload.items = tempItems;

      const promisesCompress: Promise<any>[] = [];
      const promises: Promise<string>[] = [];
      updatesOrNew.forEach(index => {
        if (data.items[index].url.file && data.items[index].url.file?.[0]) {
          promisesCompress.push(imageCompressor(data.items[index].url.file![0]));
        }
      });
      if (promisesCompress.length > 0) {
        const compressedImages: File[] = await Promise.all(promisesCompress);
        if (compressedImages.length > 0) {
          compressedImages.forEach(item => {
            promises.push(uploadFile(accessToken!, item));
          });
          const images = await Promise.all(promises);

          updatesOrNew.forEach((indexItem, index) => {
            payload.items[indexItem].url = images[index];
          });
        }
      }
      await updateCoupon({ id: id!, body: payload }).unwrap();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCreate = handleSubmit(create);
  const handleUpdate = handleSubmit(update);

  return {
    handleCreate,
    handleUpdate,
    isLoading,
    register,
    errors,
    watch,
    defaultValues,
    control,
    isSuccess,
    reset,
    defaultItems,
  };
};

export default useStoryUpsert;
