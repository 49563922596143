import { CreateArticleForm } from "_interfaces/article.interfaces";
import ContentContainer from "components/container";
import CInput from "components/input";
import CancelPopUp from "components/modal/other/Cancel";
import SavePopUp from "components/modal/other/Save";
import useArticleForm from "hooks/article/useArticleForm";
import { useEffect, useState } from "react";
import { Button } from "react-daisyui";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "services/errorHandler";
import { useLazyGetArticleByIdQuery } from "services/modules/article";
import JoditEditor from "jodit-react";
import ValidationError from "components/validation/error";
import Select from "components/select";
import useHelpForm from "hooks/help/useHelpForm";
import { useGetHelpByNameQuery } from "services/modules/help";

export const createHelpRouteName = "help-content/create";
export const updateHelpRouteName = "help-content/update/:id";

const options = [
  {
    label: "Affiliates",
    value: "Affiliates",
  },
  {
    label: "Community",
    value: "Community",
  },
  {
    label: "Membership",
    value: "Membership",
  },
]

const HelpForm = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const {data} = useGetHelpByNameQuery(id!, { skip: !id });
  const {
    register,
    errors,
    isLoading,
    watch,
    handleCreate,
    handleUpdate,
    control,
    setValue,
    reset,
  } = useHelpForm(data?.id);

  const handleCancelPopup = () => {
    setIsCancelPopupOpen(!isCancelPopupOpen);
  };

  const handleSavePopup = () => {
    setIsSavePopupOpen(!isSavePopupOpen);
  };

  useEffect(() => {
    if (id && data) {
      reset(data);
    }
  }, [data, id]);

  return (
    <ContentContainer>
      <form
        onSubmit={(e) => {
          if (id) {
            handleUpdate(e);
          } else {
            handleCreate(e);
          }
        }}
        className="flex flex-col gap-6 mb-6"
      >
        <div className="flex items-center justify-between gap-4 mb-2">
          <h3 className="text-2xl text-[#262626] font-bold">{`Create Help Content`}</h3>
          <div className="flex items-center justify-between gap-4 ml-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                void handleCancelPopup();
              }}
              className="rounded-full px-6 py-2 border-red-500/80 text-red-500/80 hover:border-red-500  hover:text-red-500"
            >
              Cancel
            </Button>
            <CancelPopUp
              isOpen={isCancelPopupOpen}
              data={id ? "Update Help Content" : "Create Help Content"}
              onClose={handleCancelPopup}
              onEdit={() => {
                navigate(-1);
                handleCancelPopup();
              }}
              menu={id ? "Update Help Content" : "Create Help Content"}
            />
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                void handleSavePopup();
              }}
              // loading={isLoading}
              className="rounded-full px-6 py-2 border-[#3AC4A0]/80 text-[#3AC4A0]/80 hover:border-[#3AC4A0]  hover:text-[#3AC4A0]"
            >
              Save
            </Button>
            <SavePopUp
              isOpen={isSavePopupOpen}
              data={id ? "Update" : "Create"}
              onClose={handleSavePopup}
              onEdit={() => {
                setIsSavePopupOpen(false);
              }}
              menu={"Article"}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="merchant"
            className="font-semibold text-[#262626]"
          >
            Type
          </label>
          <Select
            disabled={false}
            rounded={true}
            options={options}
            value={watch('group')}
            onChange={(e) => {
              setValue('group', e.value);
            }}
          />
        </div>
        <div>
          <label
            htmlFor="merchant"
            className="font-semibold text-[#262626]"
          >
            Title
          </label>
          <CInput {...register("name")} error={errors.name} />
        </div>
        <div>
          <label className="font-semibold">Description</label>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <JoditEditor
                className="without-tailwind"
                onChange={(e) => {
                  onChange(e);
                }}
                value={value}
              />
            )}
          />
          <ValidationError error={errors.description} />
        </div>
      </form>
    </ContentContainer>
  );
};

export default HelpForm;
