import { CategoryI } from "_interfaces/category.interfaces";
import ContentContainer from "components/container";
import SearchInput from "components/search-input";
import { Columns, Table } from "components/table/table";
import { Button } from "react-daisyui";
import { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import UpsertStory from "./modal/create.modal";
import { useDeleteStoryMutation, useGetStoryQuery } from "services/modules/story";
import { PagingDTO } from "_interfaces/pagination.interface";
import Pagination from "components/table/pagination";
import { StoryI } from "_interfaces/story.interfaces";
import { errorHandler } from "services/errorHandler";

export const storyManagementRouteName = "story";
const StoryPage = () => {
  const [params, setParams] = useState<PagingDTO>({
    page: 1,
    limit: 10,
  });
  const {
    data,
    isLoading,
    refetch
  } = useGetStoryQuery(params);
  const [deleteStory, deleteState] = useDeleteStoryMutation();
  const [showModal, setShowModal] = useState<{ show: boolean; data?: StoryI }>({ show: false });
  const header: Columns<StoryI>[] = [
    {
      fieldId: "index",
      label: "No",
    },
    {
      fieldId: "title",
      label: "Title",
    },
    {
      fieldId: "description",
      label: "Description",
    },
    { fieldId: "id", label: "Items", render: (item) => `${item.storyItem.length}` },
    {
      fieldId: "id",
      label: "Action",
      render: (data) => (
        <div className="flex flex-row justify-center gap-2">
          <Button
            variant="outline"
            color="info"
            startIcon={<MdEdit />}
            onClick={() => {
              setShowModal({
                show: true,
                data,
              });
            }}
          >
            Edit
          </Button>
          <Button
            variant="outline"
            color="error"
            startIcon={<MdDelete />}
            onClick={() => handleDelete(data.id)}
            loading={deleteState.isLoading}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleDelete = async (id: string) => {
    try {
      await deleteStory(id).unwrap();
      refetch();
    } catch (error) {
      errorHandler(error);
    }
  }

  return (
    <ContentContainer>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl text-[#262626] font-semibold">
          Story Management
        </h3>
        <div className="flex flex-row gap-2">
          <SearchInput
            placeholder="Search"
            onSubmit={({ text }) => {
              setParams({ page: 1, limit: 10, search: text });
            }}
          />
          <Button
            onClick={() => setShowModal({ show: true })}
            className="bg-san-juan text-white hover:bg-san-juan/90"
          >
            Create Story
          </Button>
        </div>
      </div>
      <Table
        data={data?.data}
        columns={header}
        loading={isLoading}
      />
      <Pagination
        currentPage={params.page}
        totalPages={Math.ceil((data?.meta.total ?? 0) / params.limit)}
        onPageChange={(page) => {
          setParams((prev) => ({ ...prev, page }));
        }}
      />
      <UpsertStory
        open={showModal.show}
        handleClose={(needRefetch) => {
          setShowModal({ show: false });
          if (needRefetch) {
            refetch();
          }
        }}
        data={showModal.data}
      />
    </ContentContainer>
  );
};

export default StoryPage;
