import { GetProjectQuery, GetProjectResI, ProjectI } from "_interfaces/crafter-project.interfaces";
import {
  GetProductByIdRes,
  GetProductQuery,
  GetProductResI,
  ProductPayloadI,
} from "_interfaces/post.interface";
import { Api } from "services/api";

export const crafterProjectApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getCrafterProjects: build.query<GetProjectResI, GetProjectQuery>({
      query: (params) => {
        return {
          url: "/crafter/admin/crafter",
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getProjectById: build.query<{ data: ProjectI }, string>({
      query: (id) => `/crafter/admin/crafter/${id}`,
      keepUnusedDataFor: 0,
    }),
    approveProject: build.mutation<void, { id: string; coin: number }>({
      query({ id, coin }) {
        return {
          url: `/crafter/admin/crafter/${id}/approve`,
          method: "POST",
          body: { coin },
        };
      },
    }),
    rejectProject: build.mutation<void, { id: string; reason: string }>({
      query({ id, reason }) {
        return {
          url: `/crafter/admin/crafter/${id}/reject`,
          method: "POST",
          body: { reason },
        };
      },
    }),
    deleteProject: build.mutation<void, string>({
      query(id) {
        return {
          url: `/crafter/admin/crafter/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCrafterProjectsQuery,
  useGetProjectByIdQuery,
  useApproveProjectMutation,
  useRejectProjectMutation,
  useDeleteProjectMutation,
} = crafterProjectApi;
