import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import ContentContainer from "components/container";
import CInput from "components/input";
import CancelPopUp from "components/modal/other/Cancel";
import SavePopUp from "components/modal/other/Save";
import ImageInput from "components/post/ImageInput";
import ValidationError from "components/validation/error";
import useCreateProduct from "hooks/product/useCreateProduct";
import useFilePreview from "hooks/shared/useFilePreview";
import { useEffect, useState } from "react";
import { Button, Checkbox, FileInput, Input } from "react-daisyui";
import { Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { IoClose, IoEllipsisVertical } from "react-icons/io5";
import {
  useGetCategoriesQuery,
  useGetChildCategoriesQuery,
  useGetSubCategoriesQuery,
  useLazyGetProductByIdQuery,
} from "services/modules/crafter";
import CreateCategory from "pages/category/modal/create.modal";
import MetadataModal from "./modal/metadata.modal";
import { errorHandler } from "services/errorHandler";
import { PostType, ProductFormI } from "_interfaces/post.interface";
import { calculateSEOScore } from "_helper/seoAnalyzer";

export const createProductRouteName = "create";
export const updateProductRouteName = ":id";
const CreateProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [tag, setTag] = useState("");
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showMetadata, setShowMetadata] = useState<boolean>(false);
  const [getProductById] = useLazyGetProductByIdQuery();
  const { data: category, refetch: refetchCategory } =
    useGetCategoriesQuery(undefined);
  const { data: subCategory, refetch: refetchSubCategory } =
    useGetSubCategoriesQuery(undefined);
  const { data: childCategory, refetch: refetchChildCategory } =
    useGetChildCategoriesQuery(undefined);
  const [score, setScore] = useState(0);
  const {
    register,
    errors,
    isLoading,
    watch,
    handleCreate,
    handleUpdate,
    control,
    setValue,
    reset,
  } = useCreateProduct(id);
  const { fields, remove, insert, update, append } = useFieldArray({
    control,
    name: "image",
  });
  const {
    fields: tags,
    remove: removeTag,
    append: appendTag,
  } = useFieldArray({
    control,
    name: "tags",
  });
  const image = watch("image");
  const file = watch("product.file");
  const metaDescription = watch("metaDescription");
  const metaTitle = watch("metaTitle");
  const title = watch("name");
  const enableDiscount = watch("enableDiscount");
  const desc = watch("description");
  const imagePreview = image.map(({ file }) => {
    if (file && file[0]) {
      return URL.createObjectURL(file[0]);
    }
  });

  const handleCancelPopup = () => {
    setIsCancelPopupOpen(!isCancelPopupOpen);
  };

  const handleSavePopup = () => {
    setIsSavePopupOpen(!isSavePopupOpen);
  };

  const handleAnalyze = () => {
    const newScore = calculateSEOScore(
      desc,
      tags.map((item) => item.text),
      metaDescription,
    );
    setScore(newScore);
  };

  // Menentukan warna progress bar berdasarkan skor
  const getColor = (score: number) => {
    if (score < 10) return "bg-red-500";
    if (score < 20) return "bg-yellow-500";
    if (score < 40) return "bg-yellow-400";
    if (score < 60) return "bg-yellow-300";
    if (score < 80) return "bg-yellow-200";
    return "bg-green-500";
  };

  const barValues = Array(5)
    .fill(0)
    .map((_, i) => Math.min(20, Math.max(0, score - i * 20)));

  const handleAddTag = () => {
    const tempTags = tag.split(",");
    tempTags.forEach((item) => {
      if (item.trim() !== "") {
        appendTag({ text: item.trim() });
      }
    });
    setTag("");
  };

  useEffect(() => {
    if (id) {
      getProduct();
    }
  }, [id]);

  useEffect(() => {
    if (desc && metaDescription && tags?.length > 0) {
      handleAnalyze();
    }
  }, [desc, metaDescription, tags]);

  const getProduct = async () => {
    try {
      const {
        data: { product: prod, title: titleResp, description: descriptionResp },
      } = await getProductById(id!).unwrap();
      const dataProduct: ProductFormI = {
        name: prod.name,
        description: prod.description,
        product: {
          url: prod.url,
        },
        category: prod.categories.filter((item) => item !== null),
        subCategory: prod.subCategories.filter((item) => item !== null),
        childSubCategory: prod.chilSubCategories.filter(
          (item) => item !== null,
        ),
        tags: prod.tags.map((item) => ({ text: item })),
        price: prod.price,
        coinPrice: prod.coinPrice,
        enableDiscount: prod.enableDiscount,
        discount: prod.discount,
        discountPeriod: prod.discountPeriod,
        image: prod.imageUrl.map((item) => ({ url: item })),
        fileType: prod.fileType,
        fileSize: prod.fileSize,
        metaTitle: titleResp,
        metaDescription: descriptionResp,
        postType: prod.postType,
        publishAt: prod.publishAt,
      };
      reset(dataProduct);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <ContentContainer>
      <form
        onSubmit={(e) => {
          if (id) {
            handleUpdate(e);
          } else {
            handleCreate(e);
          }
        }}
        className="flex flex-col gap-6 mb-6"
      >
        <div className="flex items-center justify-between gap-4 mb-2">
          <h3 className="text-2xl text-[#262626] font-bold">
            {id ? `Update Product` : `Create Product`}
          </h3>
          <div className="flex items-center justify-between gap-4 ml-4">
            <Button
              variant="outline"
              {...register("postType")}
              onClick={() => {
                setValue("postType", PostType.Draft);
              }}
              loading={isLoading}
              className="rounded-full px-6 py-2 border-red-500/80 text-red-500/80 hover:border-red-500  hover:text-red-500"
            >
              Draft
            </Button>
            {/* <CancelPopUp
              isOpen={isCancelPopupOpen}
              data={"Create Product"}
              onClose={handleCancelPopup}
              onEdit={() => {
                navigate(-1);
                handleCancelPopup();
              }}
              menu={"Product"}
            /> */}
            <Button
              variant="outline"
              {...register("postType")}
              onClick={() => {
                setValue("postType", PostType.Publish);
              }}
              loading={isLoading}
              className="rounded-full px-6 py-2 border-[#3AC4A0]/80 text-[#3AC4A0]/80 hover:border-[#3AC4A0]  hover:text-[#3AC4A0]"
            >
              Publish
            </Button>
            {/* <SavePopUp
              isOpen={isSavePopupOpen}
              data={"Create"}
              onClose={handleSavePopup}
              onEdit={() => {
                setIsSavePopupOpen(false);
              }}
              menu={"Product"}
            /> */}
          </div>
        </div>
        <div>
          <label
            htmlFor="merchant"
            className="font-semibold text-[#262626]"
          >
            Product Name
          </label>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => (
              <CInput
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue(
                    "metaTitle",
                    e.target.value?.replaceAll(" ", "-").toLowerCase(),
                  );
                }}
                error={errors.name}
              />
            )}
          />
        </div>
        <div>
          <label className="font-semibold">Product Description</label>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <JoditEditor
                className="without-tailwind"
                onChange={(e) => {
                  onChange(e);
                  setValue(
                    "metaDescription",
                    e.replaceAll("<br>", " ").replace(/(<([^>]+)>)/gi, ""),
                  );
                }}
                value={value}
              />
            )}
          />
          <ValidationError error={errors.description} />
        </div>
        {metaDescription && metaTitle ? (
          <>
            <div
              className="p-2 border rounded-lg shadow-lg flex flex-col gap-3 cursor-pointer"
              onClick={() => {
                setShowMetadata(true);
              }}
            >
              <label
                htmlFor="merchant"
                className="font-semibold mb-3"
              >
                Preview
              </label>
              <div className="flex flex-row gap-3">
                <div className="text-sm text-[#202124]">{`https://drizystudio.com/${metaTitle}`}</div>
                <IoEllipsisVertical className="text-gray-600" />
              </div>
              <h5 className="text-[#1a0dab] leading-3 font-normal">{`${title} - Drizy Studio`}</h5>
              <p className="text-[13px] text-[#555d66]">
                {metaDescription?.substring(0, 154)}...
              </p>
            </div>
            <div className=" w-1/2">
              <label
                htmlFor="merchant"
                className="font-semibold mb-3"
              >
                SEO Analyzer
              </label>
              <div className="flex flex-row gap-2">
                {barValues.map((value, index) => (
                  <div
                    key={index}
                    className="w-full bg-gray-200 rounded-full h-6"
                  >
                    <div
                      className={`h-6 rounded-full text-white text-center ${getColor(
                        value * 5,
                      )}`}
                      style={{ width: `${value * 5}%` }}
                    />
                  </div>
                ))}
                <label className="flex flex-row w-full">{`Score: ${score}`}</label>
              </div>
            </div>
          </>
        ) : null}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold"
            >
              Select Categories
            </label>
            <div className="border-2 rounded-lg max-h-[200px] overflow-y-auto flex flex-col gap-2 px-4 py-2">
              {/* <Checkbox
                color="info"
                size="sm"
                className="hidden"
                value={"hidden"}
                {...register("subCategory")}
              />
              <Checkbox
                color="info"
                size="sm"
                className="hidden"
                value={"hidden"}
                {...register("category")}
              />
              <Checkbox
                color="info"
                size="sm"
                className="hidden"
                value={"hidden"}
                {...register("childSubCategory")}
              /> */}
              {category?.data.map((cat, i) => (
                <>
                  <div
                    key={i}
                    className="flex flex-row gap-2 items-center"
                  >
                    <Checkbox
                      color="info"
                      size="sm"
                      id={`${cat.id}-${cat.name}`}
                      value={cat.name}
                      {...register("category")}
                    />
                    <label className="text-sm">{cat.name}</label>
                  </div>
                  {subCategory?.data
                    .filter((sub) => sub.categoryId === cat.id && sub.name !== "Seasonal")
                    .map((sub, j) => (
                      <>
                        <div
                          key={`${i}-${j}-${sub.name}`}
                          className="flex flex-row gap-2 items-center ml-6"
                        >
                          <Checkbox
                            color="info"
                            size="sm"
                            id={`${sub.id}-${sub.name}`}
                            value={sub.name}
                            {...register("subCategory")}
                          />
                          <label className="text-sm">{sub.name}</label>
                        </div>
                        {childCategory?.data
                          .filter((child) => child.subCategoryId === sub.id)
                          .map((child, k) => (
                            <div
                              key={`${j}-${k}-${child.name}`}
                              className="flex flex-row gap-2 items-center ml-12"
                            >
                              <Checkbox
                                color="info"
                                size="sm"
                                id={`${child.id}-${child.name}`}
                                value={child.name}
                                {...register("childSubCategory")}
                              />
                              <label className="text-sm">{child.name}</label>
                            </div>
                          ))}
                      </>
                    ))}
                </>
              ))}
            </div>
            <Button
              onClick={() => {
                setShowCreate(true);
              }}
              type="button"
              className="bg-san-juan text-white hover:bg-san-juan/90 disabled:bg-neutral-400 disabled:text-white disabled:cursor-not-allowed mt-2"
            >
              New Category
              <PlusIcon
                className="-mr-1 -mb-1 h-5 w-5 text-white "
                aria-hidden="true"
              />
            </Button>
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold"
            >
              Select Seasons
            </label>
            <div className="border-2 rounded-lg max-h-[200px] overflow-y-auto flex flex-col gap-2 px-4 py-2">
              {subCategory?.data
                .filter((sub) => sub.name === "Seasonal")
                .map((sub, j) => (
                  <>
                    {childCategory?.data
                      .filter((child) => child.subCategoryId === sub.id)
                      .map((child, k) => (
                        <div
                          key={`${j}-${k}-${child.name}`}
                          className="flex flex-row gap-2 items-center"
                        >
                          <Checkbox
                            color="info"
                            size="sm"
                            id={`${child.id}-${child.name}`}
                            value={child.name}
                            {...register("childSubCategory")}
                          />
                          <label className="text-sm">{child.name}</label>
                        </div>
                      ))}
                  </>
                ))}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div>
              <label
                htmlFor="merchant"
                className="font-semibold"
              >
                Product Tags
              </label>
              <div className="w-2/3 flex flex-row gap-4">
                <Input
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  className={`w-full bg-white`}
                />
                <Button
                  type="button"
                  disabled={tag.trim() === ""}
                  className="bg-san-juan text-white hover:bg-san-juan/90 disabled:bg-neutral-400 disabled:text-white disabled:cursor-not-allowed"
                  onClick={() => handleAddTag()}
                >
                  Add tag
                  <PlusIcon
                    className="-mr-1 -mb-1 h-5 w-5 text-white "
                    aria-hidden="true"
                  />
                </Button>
              </div>
              <div className="w-2/3 flex flex-row flex-wrap gap-2 mt-2">
                {tags.map((item, i) => (
                  <div
                    key={item.id}
                    className="rounded-full bg-gray-300 flex flex-row items-center gap-1 py-1 px-3"
                  >
                    <div>{item.text}</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => removeTag(i)}
                    >
                      <IoClose />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="font-semibold">Gallery Product</h1>
          <div className="flex gap-4">
            <Button
              onClick={() => {
                remove(fields.length - 1);
              }}
              disabled={fields.length === 1}
              type="button"
              className="bg-san-juan text-white hover:bg-san-juan/90 disabled:bg-neutral-400 disabled:text-white disabled:cursor-not-allowed"
            >
              Remove
              <MinusIcon
                className="-mr-1 -mb-1 h-5 w-5 text-white"
                aria-hidden="true"
              />
            </Button>
            <Button
              onClick={() => {
                insert(fields.length, { url: "", file: undefined });
              }}
              // disabled={fields.length === 4}
              type="button"
              className="bg-san-juan text-white hover:bg-san-juan/90 disabled:bg-neutral-400 disabled:text-white disabled:cursor-not-allowed"
            >
              Add Image
              <PlusIcon
                className="-mr-1 -mb-1 h-5 w-5 text-white "
                aria-hidden="true"
              />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          {fields.map((item, i) => (
            <ImageInput
              key={i}
              first={i === 0}
              isWide={fields.length % 2 === 1 && i === fields.length - 1}
              imagePreview={imagePreview[i] ?? item.url}
              register={register(`image.${i}.file`)}
            />
          ))}
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col gap-2">
            <div>
              <label className="font-semibold">Price</label>
              <div className="grid grid-cols-3 gap-2">
                {["Personal", "Commercial", "Business"].map((item, i) => (
                  <div key={`price-${item}`}>
                    <label className="text-sm">{item}</label>
                    <CInput
                      type="number"
                      {...register(`price.${i}`)}
                      error={errors.price?.[i]}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="font-semibold">Coin Price</label>
              <div className="grid grid-cols-3 gap-2">
                {["Personal", "Commercial", "Business"].map((item, i) => (
                  <div key={`coin-${item}`}>
                    <label className="text-sm">{item}</label>
                    <CInput
                      type="number"
                      {...register(`coinPrice.${i}`)}
                      error={errors.coinPrice?.[i]}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <div className="grid grid-cols-2">
                <div>
                  <label className="font-semibold">Discount</label>
                  <div className="flex flex-row gap-1.5 mt-2.5">
                    <Controller
                      control={control}
                      name="enableDiscount"
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          id="enableDiscount"
                        />
                      )}
                    />
                    <label
                      htmlFor="enableDiscount"
                      className=""
                    >
                      Activate Discount
                    </label>
                    <ValidationError error={errors.enableDiscount} />
                  </div>
                </div>
                {enableDiscount && (
                  <div>
                    <label className="font-semibold">Active Until</label>
                    <CInput
                      type="date"
                      {...register("discountPeriod")}
                      error={errors.discountPeriod}
                    />
                  </div>
                )}
              </div>
            </div>
            {enableDiscount && (
              <div>
                <label className="font-semibold">Discount Price</label>
                <div className="grid grid-cols-3 gap-2">
                  {["Personal", "Commercial", "Business"].map((item, i) => (
                    <div key={`discount-${item}`}>
                      <label className="text-sm">{item}</label>
                      <CInput
                        type="number"
                        {...register(`discount.${i}`)}
                        error={errors.discount?.[i]}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-1/4">
          <label className="font-semibold">Publish Date</label>
          <CInput
            type="date"
            {...register("publishAt")}
            error={errors.publishAt}
          />
        </div>
        <div className="p-2 border rounded-lg shadow-lg flex flex-col gap-3">
          <label
            htmlFor="merchant"
            className="font-semibold text-lg"
          >
            Product Package
          </label>
          <div className="w-1/3 flex flex-col gap-2">
            <div>
              <label className="font-semibold text-sm">Downloadable File</label>
              <div>
                <FileInput
                  {...register("product.file")}
                  size="sm"
                  accept="image/*,.zip,.rar,.7zip"
                />
              </div>
              <ValidationError error={errors.product?.file} />
            </div>
            <div>
              <label
                htmlFor="merchant"
                className="font-semibold text-sm"
              >
                File Type
              </label>
              <CInput
                {...register("fileType")}
                error={errors.fileType}
              />
            </div>
            <div>
              <label
                htmlFor="merchant"
                className="font-semibold text-sm"
              >
                File Size
              </label>
              <CInput
                type="number"
                {...register("fileSize")}
                error={errors.fileSize}
              />
            </div>
          </div>
        </div>
      </form>
      <CreateCategory
        open={showCreate}
        handleClose={(needRefetch) => {
          setShowCreate(false);
          if (needRefetch) {
            refetchCategory();
            refetchSubCategory();
            refetchChildCategory();
          }
        }}
        categories={category?.data ?? []}
        subCategories={subCategory?.data ?? []}
      />
      <MetadataModal
        open={showMetadata}
        handleClose={(data) => {
          if (data) {
            setValue("metaTitle", data.title);
            setValue("metaDescription", data.description);
          }
          setShowMetadata(false);
        }}
        title={metaTitle}
        description={metaDescription}
      />
    </ContentContainer>
  );
};

export default CreateProduct;
