import {
  GetAffiliateReqI,
  GetAffiliateResI,
  GetLinkResI,
} from "_interfaces/affiliate.interfaces";
import { PagingDTO } from "_interfaces/pagination.interface";
import { Api } from "services/api";

export const affiliateApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getAffiliator: build.query<GetAffiliateResI, GetAffiliateReqI>({
      query: (params) => ({ url: "/affiliate/admin", params }),
      keepUnusedDataFor: 0,
    }),
    getAffiliateRequest: build.query<GetAffiliateResI, PagingDTO>({
      query: (params) => ({ url: "/affiliate/admin/request", params }),
      keepUnusedDataFor: 0,
    }),
    getAffiliateLinks: build.query<
      GetLinkResI,
      { id: string; params: PagingDTO }
    >({
      query: ({ id, params }) => ({ url: `/affiliate/admin/${id}`, params }),
      keepUnusedDataFor: 0,
    }),
    approve: build.mutation<void, { id: string; commissionRate: number }>({
      query: ({ id, commissionRate }) => ({
        url: `/affiliate/admin/approve/${id}`,
        method: "POST",
        body: {
          commissionRate,
        },
      }),
    }),
    updateAfilliator: build.mutation<
      void,
      { id: string; commissionRate: number }
    >({
      query: ({ id, commissionRate }) => ({
        url: `/affiliate/admin/${id}`,
        method: "POST",
        body: {
          commissionRate,
        },
      }),
    }),
    reject: build.mutation<void, string>({
      query: (id) => ({
        url: `/affiliate/admin/reject/${id}`,
        method: "POST",
        body: {
          reason: "Rejected by admin",
        },
      }),
    }),
    ban: build.mutation<void, string>({
      query: (id) => ({
        url: `/affiliate/admin/ban/${id}`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAffiliateRequestQuery,
  useApproveMutation,
  useRejectMutation,
  useGetAffiliatorQuery,
  useUpdateAfilliatorMutation,
  useBanMutation,
  useGetAffiliateLinksQuery,
} = affiliateApi;

