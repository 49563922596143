import { PagingDTO } from "_interfaces/pagination.interface";
import { CreateStoryPayloadI, GetStoryResI } from "_interfaces/story.interfaces";
import { Api } from "services/api";

export const storyApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getStory: build.query<GetStoryResI, PagingDTO>({
      query: (params) => {
        return {
          url: "/crafter/admin/story",
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getStoryById: build.query<any, string>({
      query: (id) => `/crafter/admin/story/${id}`,
      keepUnusedDataFor: 0,
    }),
    createStory: build.mutation<void, CreateStoryPayloadI>({
      query(body) {
        return {
          url: `/crafter/admin/story`,
          method: "POST",
          body,
        };
      },
    }),
    updateStory: build.mutation<
      void,
      { id: string; body: CreateStoryPayloadI }
    >({
      query({ body, id }) {
        return {
          url: `/crafter/admin/story/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    deleteStory: build.mutation<void, string>({
      query(id) {
        return {
          url: `/crafter/admin/story/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStoryQuery,
  useGetStoryByIdQuery,
  useCreateStoryMutation,
  useUpdateStoryMutation,
  useDeleteStoryMutation,
} = storyApi;
