import { CouponI } from "_interfaces/coupon.interfaces";
import CInput from "components/input";
import ValidationError from "components/validation/error";
import useCouponUpsert from "hooks/coupon/useCouponUpsert";
import moment from "moment";
import { useEffect } from "react";
import { Button, Modal } from "react-daisyui";
import { Controller } from "react-hook-form";
import { IoClose } from "react-icons/io5";

const UpsertCouponModal = ({
  open,
  handleClose,
  data,
}: {
  open: boolean;
  handleClose: (refetch?: boolean) => void;
  data?: CouponI;
}) => {
  const { handleCreate, handleUpdate, errors, register, control, isLoading, isSuccess, reset } =
    useCouponUpsert(data?.id);

  useEffect(() => {
    if (isSuccess) {
      handleClose(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        code: data.code,
        expiredAt: moment(data.expiredAt).format("YYYY-MM-DD"),
        percentage: data.percentage,
      });
    }
  }, [data?.id]);

  return (
    <Modal
      className="bg-white w-1/3 max-w-[900px]"
      open={open}
    >
      <Modal.Header className="flex flex-row justify-between">
        {data ? "Update Coupon" : "Create New Coupon"}
        <IoClose onClick={() => handleClose()} />
      </Modal.Header>
      <Modal.Body className="overflow-scroll">
        <form
          className="p-2 flex flex-col gap-4"
          onSubmit={data ? handleUpdate : handleCreate}
        >
          <div>
            <label
              htmlFor="merchantID"
              className="font-semibold text-[#262626]"
            >
              Coupon Name
            </label>
            <CInput
              {...register("name")}
              type="text"
            />
            <ValidationError error={errors.name} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Coupon Code
            </label>
            <Controller control={control} name="code" render={({ field: { onChange, value } }) =>
              <CInput value={value} onChange={(e) => onChange(e.target.value.toUpperCase())} />
            } />
            <ValidationError error={errors.code} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Percentage
            </label>
            <CInput type="number" {...register("percentage")} />
            <ValidationError error={errors.percentage} />
          </div>
          <div>
            <label
              htmlFor="merchant"
              className="font-semibold text-[#262626]"
            >
              Expired At
            </label>
            <CInput type="date" {...register("expiredAt")} />
            <ValidationError error={errors.expiredAt} />
          </div>
          <div className="w-full flex flex-row justify-end gap-4 col-span-5 mt-10">
            <Button
              type="reset"
              className="border-seeds text-seeds"
              onClick={() => handleClose()}
              loading={isLoading}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-san-juan text-white hover:bg-san-juan/90"
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpsertCouponModal;
